import { Grid, h, html as htmlGrid } from 'gridjs'
import AvatarPersona from './AvatarPersona.js'

const { tipoSistema } = window
const { isFundacao, initTipy } = GLOBAL
export default {
	props: ['data', 'data_inicial_politica', 'data_final_politica'],
	emits: [
		'open-modal',
		'open-modal-with-atendimento',
		'ordenate',
		'open-send-email',
	],
	data() {
		return {
			isFundacao: isFundacao(),
			tableAtendimento: null,
			atendimentos: []
		}
	},

	components: {
		AvatarPersona,
	},

	methods: {
		openModal(id, politica, atendimentoID) {
			this.$emit('open-modal', { id, politica, atendimentoID })
		},


		generateStatusColor(status) {
			if (status === 'Atrasado') return 'color: #c11f39 !important;'
			else if (status === 'Realizado') return 'color: #18b25d !important;'
			else if (status === 'Pendente') return 'color: #ffbb0b !important;'
			else return 'color: #252526 !important;'
		},

		columnsTableAtendimento() {
			const columns = [
				{ id: 'id', name: 'id', hidden: true },
				{
					id: 'name',
					name: 'Nomes',
					width: '260px',
					formatter: (cell, row) => {
						const id = row._cells[0]?.data
						const atendimento = this.atendimentos?.find(atendimento => atendimento.id === Number(id))

						const typePolitica = isFundacao() ? atendimento.politica_fundacao : atendimento.politica_cliente

						const columnName = htmlGrid(html`<div class="inline-flex justify-center items-center gap-8 btn-open-modal-${id}" 
							data-tippy-content="${atendimento.nome_fundacao}">
								<svg class="h-40 w-40 shrink-0" style="${GLOBAL.generateAvatarColor(typePolitica)}">
									<use xlink:href="#icon_person"></use>
								</svg>	
								${cell}			
							</div>
								`)
						setTimeout(() => {
							const openModal = document.querySelector(`.btn-open-modal-${id}`);
							if (openModal) {
								openModal.addEventListener('click', async () => {
									this.handleOpenModal(atendimento)
								});
							}
						}, 0);

						return columnName


					},
				},
				{
					id: 'last_attendant',
					name: 'Último Atendimento',
					width: '150px',
					formatter: (cell, row) => {
						const id = row._cells[0]?.data
						const atendimento = this.atendimentos?.find(atendimento => atendimento.id === Number(id))

						return this.ultimaData(atendimento)
					},
				},

				{
					id: 'status',
					name: 'Status',
					width: '100px',
					formatter: (cell) => {

						return htmlGrid(html`<div style="${this.generateStatusColor(cell)}">	
								${cell}			
							</div>
								`)
					},
				},
				{
					id: 'actions',
					name: '',
					width: '80px', formatter: (cell, row) => {

						const id = row._cells[0]?.data
						const atendimento = this.atendimentos?.find(atendimento => atendimento.id === Number(id))

						const columnActions = htmlGrid(`
							<div class="flex gap-8 items-center justify-center ">
									<div
										data-tippy-content="Enviar E-mail"
										class="tippy-el btn-action-${id}">
										<div
											class="cursor-pointer hover:scale-125 transition-transform inline-flex active:scale-110 justify-center w-full ">
											<svg
												class="h-32 w-32 border border-transparent rounded-lg hover:border-neutral-60 ">
												<use xlink:href="#icon_email"></use>
											</svg>
										</div>
									</div>
								<div data-tippy-content="Enviar E-mail"
									class="tippy-el btn-criar-${id}" >
				
									<div data-tippy-content="Criar atendimento" class="tippy-el">
										<div class="cursor-pointer hover:scale-125 transition-transform inline-flex active:scale-110 justify-center w-full">
											<svg class="h-32 w-32 border border-transparent rounded-lg hover:border-neutral-60">
												<use xlink:href="#icon_check"></use>
											</svg>
										</div>
									</div>
								</div>
							</div>
			
    `);

						setTimeout(() => {
							const sendEmail = document.querySelector(`.btn-action-${id}`);
							if (sendEmail) {
								sendEmail.addEventListener('click', async () => {
									this.openSendEmail(isFundacao() ? atendimento.fundacao : atendimento.cliente_pf)
								});
							}
							const criarAtendimmento = document.querySelector(`.btn-criar-${id}`);
							if (criarAtendimmento) {
								criarAtendimmento.addEventListener('click', async () => {
									this.handleOpenModal(atendimento, true)
								});
							}
						}, 0);

						return columnActions
					},
				}



			]
			return columns
		},

		async atualizarAgendamentoDetalhes() {
			const exibicao = this.isFundacao ? '?exibicao=fundacao' : '?exibicao=cliente_pf'
			const dataInicialPoliticaFormated = this.data_inicial_politica.split('/').reverse().join('-')
			const dataFinalPoliticaFormated = this.data_final_politica.split('/').reverse().join('-')
			this.tableAtendimento.updateConfig({
				server: {
					url: `/api/atendimentos/${exibicao}&data_inicial=${dataInicialPoliticaFormated}&data_final=${dataFinalPoliticaFormated}`,
					then: (responseAtendimentos) => {
						const atendimentos = responseAtendimentos?.results?.atendimentos
						this.atendimentos = atendimentos
						let arrayForTable
						if (atendimentos) {
							if (isFundacao()) {
								arrayForTable = atendimentos.map(({ id, sigla_fundacao, ultimo_atendimento_fundacao, status }) => [id, sigla_fundacao, ultimo_atendimento_fundacao, status, 'teste'])
							} else {
								arrayForTable = atendimentos.map(({ id, nome_cliente_pf, ultimo_atendimento_cliente_pf, status }) => [id, nome_cliente_pf, ultimo_atendimento_cliente_pf, status, 'teste'])

							}
						}
						return arrayForTable
					},
					total: (responseAtendimentos) => responseAtendimentos?.count
				},
				search: false,
				pagination: {
					enabled: true,
					limit: 100,
					server: {
						url: (urlApi, page, limit) => {
							return `${urlApi}&page=${++page}&paginate_by=${limit}`
						},
					},
				},
      }).forceRender()
		},

		createTableAtendimento() {

			const columns = this.columnsTableAtendimento()
			const exibicao = this.isFundacao ? '?exibicao=fundacao' : '?exibicao=cliente_pf'

			const dataInicialPoliticaFormated = this.data_inicial_politica.split('/').reverse().join('-')
			const dataFinalPoliticaFormated = this.data_final_politica.split('/').reverse().join('-')

			this.tableAtendimento = new Grid({
				resizable: true,
				columns,
				autoWidth: true,
				width: '100%',
				sort: false,
				server: {
					url: `/api/atendimentos/${exibicao}&data_inicial=${dataInicialPoliticaFormated}&data_final=${dataFinalPoliticaFormated}`,
					then: (responseAtendimentos) => {
						const atendimentos = responseAtendimentos?.results?.atendimentos
						this.atendimentos = atendimentos
						let arrayForTable
						if (atendimentos) {
							if (isFundacao()) {
								arrayForTable = atendimentos.map(({ id, sigla_fundacao, ultimo_atendimento_fundacao, status }) => [id, sigla_fundacao, ultimo_atendimento_fundacao, status, 'teste'])
							} else {
								arrayForTable = atendimentos.map(({ id, nome_cliente_pf, ultimo_atendimento_cliente_pf, status }) => [id, nome_cliente_pf, ultimo_atendimento_cliente_pf, status, 'teste'])

							}
						}
						return arrayForTable
					},
					total: (responseAtendimentos) => responseAtendimentos?.count
				},
				search: false,
				pagination: {
					enabled: true,
					limit: 100,
					server: {
						url: (urlApi, page, limit) => {
							return `${urlApi}&page=${++page}&paginate_by=${limit}`
						},
					},
				},
				style: {
					container: {
						border: 'none',
					},
					table: {
						border: 'none',
					},
					tr: {
						border: 'none',
					},

					th: {
						'border-bottom': '1px solid #e9ecef',
						// 'flex-wrap': 'wrap',
						// 'max-width': '200px'
					},
					td: {
						'border-bottom': '1px solid #e9ecef',
						cursor: 'pointer',
					},
					footer: {
						border: 'none',
					},
				},
				language: {
					search: {
						placeholder: 'Busque aqui...',
					},
					pagination: {
						previous: '<',
						next: '>',
						to: '/',
						of: 'de',
						showing: 'Mostrando',
						results: () => 'Resultados',
					},
					loading: 'Carregando dados...',
					noRecordsFound: 'Nenhum dado encontrado.'
				},
			})

			this.tableAtendimento.render(document.querySelector('#table_atendimentos'))
			this.tableAtendimento.on('ready', () => {
				const sendEmail = document.querySelectorAll(`.btn`);
				console.log({sendEmail})
			 })
			
		},

		openModalWithAtendimento(id, politica, atendimentoID) {
			this.$emit('open-modal-with-atendimento', {
				id,
				politica,
				atendimentoID,
			})
		},

		ordenate(event, key, dataKey, number) {
			this.$emit('ordenate', { event, key, dataKey, ...number })
		},

		canculateDiference(end) {
			if (end == 'Sem atendimentos') return '-'
			else return GLOBAL.calculateDateDiference(end)
		},

		handleOpenModal(atendimento, withAtendimento) {
			const clienteID = this.isFundacao
				? atendimento.fundacao
				: atendimento.cliente_pf
			const politica = this.isFundacao
				? atendimento.politica_fundacao
				: atendimento.politica_cliente
			if (withAtendimento) {
				this.openModalWithAtendimento(clienteID, politica, atendimento.id)
			} else {
				this.openModal(clienteID, politica, atendimento.id)
			}
		},

		ultimaData(atendimento) {
			return isFundacao
				? this.canculateDiference(atendimento.ultimo_atendimento_fundacao)
				: this.canculateDiference(atendimento.ultimo_atendimento_cliente_pf)
		},

		openSendEmail(id) {
			this.$emit('open-send-email', id)
		},
	},

	computed: {
		skeletonClass() {
			return { skeleton: this.data[0]?.placeholder }
		},
	},
	mounted() {
		this.createTableAtendimento()
		setTimeout(() => {
				tippy('[data-tippy-content]', {
				allowHTML: true,
				placement: "top",
				boundary: "HTMLElement"
			})
			}, 1000)
	},

	template: html`
			<div class="w-[100%] max-h-[29.15rem] overflow-y-auto overflow-x-scroll flex-1 table-wrapper  box-border relative">
				<table id="table_atendimentos" class="!flex" js-table-ordenation ></table>
			</div>
	`,
}
