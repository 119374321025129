import {
	CARTEIRA_MARGINS_CHART_OPTIONS,
	CARTEIRA_MARGINS_CHART_ID,
} from '../constants/carteira_margins'

import { useCharts } from './useCharts'
import { useConcentracao } from './useConcentracao'

const { buildMarginsLines, serializeLimites } = useConcentracao()
const { createChart, setColorsInFunctionOfAHexadecimalColor } = useCharts()

export function useCarteiraMarginsChart() {
	function createCarteiraMarginsChart(graficoCarteira, margins) {
		const graficoCarteiraOrdered = GLOBAL.mergeSortAnArrayWithObjects(
			graficoCarteira?.map((i) => ({
				...i,
				porcentagem: Number(i.porcentagem),
			})),
			'porcentagem',
			true
		)
		const seriesData = graficoCarteiraOrdered.map(({ porcentagem }) =>
			parseFloat(porcentagem)
		)
		const investimentosMargins = graficoCarteiraOrdered.map((itemCarteira) => {
			const investimentoMargins = margins.find(
				({ tipo_investimento }) =>
					itemCarteira.tipo_investimento === tipo_investimento
			)

			if (!investimentoMargins) return null

			const { limite_inferior, limite_superior } = investimentoMargins

			return serializeLimites(limite_inferior, limite_superior)
		})
		const categories = graficoCarteiraOrdered.map(
			({ tipo_investimento }) => tipo_investimento
		)

		const colors = GLOBAL.defineColors(categories)

		const options = {
			...CARTEIRA_MARGINS_CHART_OPTIONS,
			colors: colors,
			series: [{ data: seriesData }],

			xaxis: {
				type: 'category',
				categories: categories,
				max: 100,
				stepSize: 10,
				labels: {
					formatter: (value) => {
						return `${value}%`
					},
				},
			},

			tooltip: {
				enabled: true,
				shared: false,
				followCursor: true,
				x: {
					show: false,
				},
				custom: handleCustomTooltip(investimentosMargins, categories),
			},
		}

		createChart(CARTEIRA_MARGINS_CHART_ID, options)
		buildMarginsLines(CARTEIRA_MARGINS_CHART_ID, investimentosMargins)
		window.addEventListener('resize', () =>
			setTimeout(
				() =>
					buildMarginsLines(CARTEIRA_MARGINS_CHART_ID, investimentosMargins),
				500
			)
		)
	}

	function handleCustomTooltip(margins, categories) {
		return ({ series, seriesIndex, dataPointIndex }) => {
			const margin = margins[dataPointIndex]
			const tipoInvestimento = categories[dataPointIndex]

			return `
			<div class="arrow_box p-8 bg-white"
			>
				<p class="font-medium text-sm text-neutral-100">${tipoInvestimento}</p>
				<p class="text-neutral-70 font-normal text-xs">Porcentagem: ${parseFloat(
					series[seriesIndex][dataPointIndex]
				).toFixed(2)}%</p>
				${
					margin
						? `<p class="text-neutral-70 font-normal text-xs">Margem inferior: ${margin[0]}%</p>
				<p class="text-neutral-70 font-normal text-xs">Margem superior: ${margin[1]}%</p>`
						: ``
				}
			</div>
		`
		}
	}

	return {
		createCarteiraMarginsChart,
	}
}
