import { TEMPO_VENCIMENTO_CHARTS } from "../constants/tempo_vencimento"

export function useChartTempoVencimento() {
	let tempo_vencimento
	let maxTaxa 
	async function createTempoVencimentoCharts(data) {
		tempo_vencimento = data

		const ativos_geral = [...tempo_vencimento?.data_ipca.ativos, tempo_vencimento?.data_pos.ativos, ...tempo_vencimento?.data_pre.ativos]
		const anos_ate_vencimento_geral = [
			...ativos_geral.map(i => i.anos_ate_vencimento)
		]
		const anos_filtrados = anos_ate_vencimento_geral.filter(valor => !isNaN(valor) && typeof valor === 'number')
		maxTaxa = Math.max(...anos_filtrados)
		
		try {
			TEMPO_VENCIMENTO_CHARTS.forEach(({ key, idChart, idEl, colors, title }) => setChartTempoVencimento(key, idChart, idEl, colors, title))

		} catch (e) {
			GLOBAL.errorDefault(e)
		} finally {
			NProgress.done()
		}
	}

	function setChartTempoVencimento(key, idChart, idEl, colors, title) {
		const { ativos, porcentagem_categoria, porcentagem_global, ponto_medio } =
		tempo_vencimento[key]

		const series = []
		const label = document.getElementById(
			title
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
		)

		function verifica(taxa_com_gross_up, taxa_de_compra){
			if(taxa_com_gross_up && parseFloat(taxa_com_gross_up) > 0 ){
				return taxa_com_gross_up
			}else{
				return taxa_de_compra
			}
		}
	

		series.push({
			name: title,
			data: ativos.map(({ anos_ate_vencimento, taxa_com_gross_up, taxa_de_compra }) =>{
				const taxa = verifica(taxa_com_gross_up, taxa_de_compra)
				return  [
					anos_ate_vencimento,
					taxa,
				]
			}),
		})

		series.push({
			name: 'Ponto Médio',
			data: [
				[
					parseFloat(ponto_medio.anos_ate_vencimento),
					parseFloat(ponto_medio.taxa_de_compra),
				],
			],
		})

		label.innerHTML = html`<div
			class="grid grid-cols-2 gap-0 border ml-4 rounded  "
			style="border-color:rgba(193, 31, 57, 0.10); width: 9rem;">
			<div
				class="col-span-full flex items-center justify-center p-4 text-xs  font-semibold"
				style="background-color:rgba(193, 31, 57, 0.10); color:rgba(193, 31, 57, 1); ">
				${title}
			</div>
			<div
				class="border-[.0313rem] border-neutral-30 text-xs font-normal pl-6 pr-6 py-4 border-b-0 border-l-0 text-neutral-100">
				Categoria
			</div>
			<div
				class="border-[.0313rem] border-neutral-30 text-xs font-normal pl-6 pr-6 py-4 border-b-0 border-r-0 text-neutral-100">
				${parseFloat(porcentagem_categoria).toFixed(2)}%
			</div>
			<div
				class="border-[.0313rem] border-neutral-30 text-xs font-normal pl-6 pr-6 py-4 border-b-0 border-l-0 text-neutral-100">
				Global
			</div>
			<div
				class="border-[.0313rem] border-neutral-30 text-xs font-normal pl-6 pr-6 py-4 border-b-0 border-r-0 text-neutral-100">
				${parseFloat(porcentagem_global).toFixed(2)}%
			</div>
		</div> `

		const options = {
			chart: {
				id: idChart,
				height: 450,
				width: `100%`,
				type: 'scatter',
				zoom: {
					enabled: false,
					type: 'xy',
				},
				toolbar: {
					show: false,
				},
			},
			colors: colors,
			title: {
				text: undefined,
				align: 'left',

				floating: false,
				offsetY: -4,
				style: {
					fontSize: '14px',
					fontWeight: 'normal',
					color: '#252526',
				},
			},
			subtitle: {
				text: undefined,
				align: 'left',
				offsetY: 14,
				style: {
					fontSize: '12px',
					fontWeight: '600',
					color: '#C11F39',
				},
				floating: false,
			},
			tooltip: {
				enabled: true,
				shared: false,
				followCursor: true,
				x: {
					show: false,
				},
				custom: (seriesName) => {
					const ativo = ativos[seriesName.dataPointIndex]

					if (ativo) {
						const {
							valor,
							data_compra,
							nome,
							data_vencimento,
							pr_categoria,
							pr_global,
							pr_estrategia,
							taxa_de_compra,
							taxa_com_gross_up
						} = ativo

						return seriesName.seriesIndex === 0
							? `<div class="arrow_box p-8 bg-white">
									<p class="font-medium text-sm text-neutral-100">${
										nome ? String(nome).toUpperCase() : '-'
									}</p>
									<p class="text-neutral-70 font-normal text-xs">Valor: ${GLOBAL.formatReal2(
										valor
									)}</p>
									<p class="text-neutral-70 font-normal text-xs">Pr. Categoria: ${parseFloat(
										pr_categoria
									).toFixed(2)}%</p>
									<p class="text-neutral-70 font-normal text-xs">Pr. Estrategia: ${parseFloat(
										pr_estrategia
									).toFixed(2)}%</p>
									<p class="text-neutral-70 font-normal text-xs">Pr. Global: ${parseFloat(
										pr_global
									).toFixed(2)}%</p>
									<p class="text-neutral-70 font-normal text-xs">Taxa de compra: ${parseFloat(
										taxa_de_compra
									).toFixed(2)}%</p>		
									<p class="text-neutral-70 font-normal text-xs">Taxa com Gross Up: ${parseFloat(
										taxa_com_gross_up
									).toFixed(2)}%</p>	
									<p class="text-neutral-70 font-normal text-xs">Data de vencimento: ${GLOBAL.fDate(
										data_vencimento
									)}</p>
									<p class="text-neutral-70 font-normal text-xs">Data de compra: ${GLOBAL.fDate(
										data_compra
									)}</p>
								</div>`
							: `<div class="arrow_box p-8 bg-white">
							<p class="font-medium text-sm text-neutral-100">${String(
								title
							).toUpperCase()}</p>
							<p class="text-neutral-70 font-normal text-xs">Data Média: ${GLOBAL.calcData(
								parseFloat(ponto_medio.anos_ate_vencimento) * 365
							)}</p>
							
							<p class="text-neutral-70 font-normal text-xs">Taxa Média: ${parseFloat(
								ponto_medio.taxa_de_compra
							).toFixed(2)}%</p>
							<p class="text-neutral-70 font-normal text-xs">Total: ${GLOBAL.formatReal2(
								parseFloat(ponto_medio.valor).toFixed(2)
							)}</p>
							
						</div>`
					}
				},
			},
			series: series,
			xaxis: {
				min: 0,
				max:maxTaxa,
				type: 'numeric',
				decimalsInFloat: 2,
				title: {
					text: 'Anos até o vencimento do ativo.',
				},
			},
			yaxis: {
				show: true,
				showAlways: false,
				showForNullSeries: true,
				decimalsInFloat: 2,
				labels: {
					formatter: (val) => `${parseFloat(val).toFixed(2)}%`,
				},
			},
		}

		new ApexCharts(document.querySelector(idEl), options).render()
	}

	return {
		setChartTempoVencimento,
		createTempoVencimentoCharts
	}
}