
NProgress.configure({ trickleSpeed: 40, trickle: true, minimum: 0.65 })


export default {
	props: [ "solicitacao"],

	methods: {
	},

	mounted() {
	},

	template: html`<section
			class="modal--content rounded-md flex-1 !h-max overflow-[initial]"
			data-id="modal_aprovacao">
			<header
				class="flex items-center justify-between px-24 pt-24 pb-16 bg-primary-pure">
				<h2 class="text-lg font-medium text-white">{{solicitacao.header}} </h2>
				<button
					type="button"
					class="w-32 h-32 transition-transform rounded-full cursor-pointer hover:scale-125 bg-white/10 close-btn"
					data-xclose="modal_padrao_vue">
					<svg class="w-32 h-32">
						<use xlink:href="#icon_close"></use>
					</svg>
				</button>
			</header>

			<section v-show="solicitacao.tipo === 'deleta_atendimento'" class="p-24 flex flex-col gap-16">
					<div class="flex flex-col gap-4" >
						<span class="text-neutral-70 text-sm">Cliente PF</span>
						<span class="text-neutral-100 text-sm" v-html="solicitacao?.cliente_pf || '-'"></span>
				</div>
				<div class="grid grid-cols-2  gap-16" >

					<div class="flex flex-col gap-4" >
							<span class="text-neutral-70 text-sm">Usuário solicitante</span>
							<span class="text-neutral-100 text-sm" v-html="solicitacao?.usuario_solicitante || '-'"></span>
					</div>
					<div class="flex flex-col gap-4" >
							<span class="text-neutral-70 text-sm">Usuário aprovador</span>
							<span class="text-neutral-100 text-sm" v-html="solicitacao?.usuario_aprovador || '-'"></span>
					</div>
				</div>
				<div class="grid grid-cols-2  gap-16" >

					<div class="flex flex-col gap-4" >
							<span class="text-neutral-70 text-sm">Status</span>
							<span class="text-neutral-100 text-sm" v-html="solicitacao?.status_exclusao || '-'"></span>
					</div>
					<div class="flex flex-col gap-4" >
							<span class="text-neutral-70 text-sm">Data de solicitação</span>
							<span class="text-neutral-100 text-sm" v-html="solicitacao?.data_solicitacao || '-'"></span>
					</div>
				</div>
				<div class="flex flex-col gap-4" >
						<span class="text-neutral-70 text-sm">Descrição</span>
						<span class="text-neutral-100 text-sm" v-html="solicitacao?.descricao || '-'"></span>
				</div>
			</section>

			<section v-show="solicitacao.tipo ===  'edita_atendimento'" class="p-24">
				
				<div v-if="solicitacao" class="flex flex-col gap-4">
					<span class="text-neutral-70 text-sm">Nova Descrição</span>
					<span class="text-neutral-100 text-sm" v-html="solicitacao?.conteudo_editado || '-'"></span>
				</div>

			</section>

			<section v-show="solicitacao.tipo === 'edita_contato'" class="p-24 flex flex-col gap-16">
				
				<div class="flex flex-col gap-4" >
						<span class="text-neutral-70 text-sm">Email</span>
						<span class="text-neutral-100 text-sm" v-html="solicitacao?.email || '-'"></span>
				</div>
				<div class="flex flex-col gap-4" >
						<span class="text-neutral-70 text-sm">Celular</span>
						<span class="text-neutral-100 text-sm" v-html="solicitacao?.celular || '-'"></span>
				</div>
				<div class="flex flex-col gap-4" >
						<span class="text-neutral-70 text-sm">Descrição</span>
						<span class="text-neutral-100 text-sm" v-html="solicitacao?.descricao || '-'"></span>
				</div>
				<div class="flex flex-col gap-4" >
						<span class="text-neutral-70 text-sm">Email</span>
						<span class="text-neutral-100 text-sm" v-html="solicitacao?.email || '-'"></span>
				</div>

			</section>
		</section>
	
	`
}


