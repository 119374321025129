import {
	CARTEIRA_ALVOS_CHART_ID,
	CARTEIRA_ALVOS_CHART_OPTIONS,
} from '../constants/carteira_alvos'

import { useCharts } from './useCharts'

const { createChart, setColorsInFunctionOfAHexadecimalColor } = useCharts()

export function useCarteiraAlvosChart() {
	function createCarteiraAlvosChart(graficoCarteira, concentracao) {
		const graficoCarteiraOrdered = GLOBAL.mergeSortAnArrayWithObjects(
			graficoCarteira?.map((i) => ({
				...i,
				porcentagem: Number(i.porcentagem),
			})),
			'porcentagem',
			true
		)

		const concentracaoOrdered = GLOBAL.mergeSortAnArrayWithObjects(
			concentracao?.map((i) => ({
				...i,
				porcentagem: Number(i.porcentagem),
			})),
			'porcentagem',
			true
		)

		let series = concentracaoOrdered.map((item) => {
			const itemCarteira = graficoCarteira.find(
				({ tipo_investimento }) => tipo_investimento === item.tipo_investimento
			)

			return {
				name: item.tipo_investimento,
				data: [
					parseFloat(item.alvo),
					itemCarteira?.porcentagem ? parseFloat(itemCarteira.porcentagem) : 0,
				],
				porcentagem: itemCarteira?.porcentagem
					? Number(itemCarteira.porcentagem)
					: 0,
			}
		})
		series = [
			...series,
			...graficoCarteiraOrdered
				.filter(
					({ tipo_investimento }) =>
						!series.some((item) => item.name === tipo_investimento)
				)
				.map((item) => {
					return {
						name: item.tipo_investimento,
						data: [0, parseFloat(item.porcentagem)],
						porcentagem: Number(item.porcentagem),
					}
				}),
		]

		series = GLOBAL.mergeSortAnArrayWithObjects(series, 'porcentagem', true)

		const categories = series.map(({ name }) => name)
		const colors = GLOBAL.defineColors(categories)
		const options = {
			...CARTEIRA_ALVOS_CHART_OPTIONS,
			series,
			colors: colors,
		}

		createChart(CARTEIRA_ALVOS_CHART_ID, options)
	}

	return {
		createCarteiraAlvosChart,
	}
}
