import StepHeaderItem from './StepHeaderItem.js'

export default {
	props: {
		arrayOfSteps: Array,
		currentStep: Number,
	},

	components: {
		StepHeaderItem,
	},

	data() {
		return {
			isMobile: window.innerWidth < 992,

			prevStep: Number,
			nextStep: Number,
		}
	},

	computed: {
		steps() {
			const [prev, next] = this.setSteps()

			if (this.isMobile) {
				console.log(next)
				if (next === 10) {
					return this.arrayOfSteps.slice(prev - 1, next)
				} else if (next === 1) {
					return this.arrayOfSteps.slice(prev + 1, next + 2)
				} else {
					return this.arrayOfSteps.slice(prev, next + 1)
				}
			} else {
				console.log(this.arrayOfSteps, 'else')
				return this.arrayOfSteps
			}
		},
	},

	methods: {
		setSteps() {
			return [this.currentStep - 1, this.currentStep + 1]
		},
	},

	created() {
		this.setSteps()
	},

	template: html`
		<div
			class="container step-line-container items-center h-full justify-center"
			:class="{'mobile': isMobile}">
			<template v-for="step in steps" :key="step.id">
				<step-header-item
					:link="step.link"
					:step-id="step.id"
					:title="step.title"
					:current-step="currentStep"></step-header-item>
			</template>
		</div>
	`,
}
