const { ref } = Vue;

export default {
	emits: [],

	props: {
		titulo: { type: String, required: true },
		close: { type: Function, required: true },
	},

	setup(props, ctx) {
		// const modal_padrao = modal_padrao;
		// return {
		// 	modal_padrao,
		// };
	},

	template: html`
		<header
			class="flex items-center justify-between px-24 pt-24 pb-16 bg-primary-pure"
		>
			<h2 class="text-lg font-medium text-white">{{titulo}}</h2>
			<button
				type="button"
				class="w-32 h-32 transition-transform rounded-full cursor-pointer hover:scale-125 bg-white/10 close-btn"
				data-xclose="modal_padrao"
				@click="close"
			>
				<svg class="w-32 h-32">
					<use xlink:href="#icon_close"></use>
				</svg>
			</button>
		</header>
	`,
};
