import { RATING_CHART_ID } from '../constants/rating_charts'


import { useCharts } from './useCharts'

const { createChart, setColorsInFunctionOfAHexadecimalColor } = useCharts()

export function useRatingChart() {
	function createRatingChart(dados

	) {
		const dadosValidos = Object.entries(dados).filter(dado => dado[1] !== 0)
		const labels = dadosValidos.map(dado => dado[0]);
		const series = dadosValidos.map(dado => dado[1]);
		
		const colors = GLOBAL.defineColors(labels,'tipos_rating')
		const options = {
			series,
			chart: {
				width: '100%',
				height: 370,
				id: 'chart-rating',
				type: 'pie',
			},
			noData: {
				text: 'Sem valores'
			},
			labels,
			legend: {
				position: 'bottom',
				onItemClick: {
					toggleDataSeries: false 
				},
				 onItemHover: {
          highlightDataSeries: false
      },
				
			},
			colors,
			dataLabels: {
				enabled: false
			},
			tooltip: {
				enabled: true,
				shared: true,
				intersect: false,
				custom: (seriesName) => {
					const currentValue = seriesName.series[seriesName.seriesIndex]
					const currentIndex = labels[seriesName.seriesIndex]
					const bgColor = colors[seriesName.seriesIndex]
					return `<div class="arrow_box p-8 !bg-[${bgColor}]" style="background: ${bgColor} !important"
			>
				<p class="font-semibold text-sm text-white">Rating</p>
				<p class="font-medium text-sm text-white">${currentIndex} : ${currentValue}%</p>
				
			</div>`
				},
					
			},
			

		};



		createChart(RATING_CHART_ID, options)

	}

	return {
		createRatingChart,
	}
}

