export const CARTEIRA_ALVOS_CHART_OPTIONS = {
	chart: {
		type: 'bar',
		height: 200,
		stacked: true,
		id: 'chartCarteiraAlvos',
		fontFamily: 'Roboto Flex, sans-serif',
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	plotOptions: {
		bar: {
			horizontal: true,
			height: 27,
			dataLabels: {
				total: {
					enabled: false,
				},
			},
		},
	},
	dataLabels: {
		enabled: true,
		enabledOnSeries: true,
		formatter: (val) => {
      return `${parseFloat(val).toFixed(2)}%`
  	},
		style: {
      fontSize: '10px',
  	},
	},
	title: {
		text: 'Carteira vs Alvos',
		margin: 4,
		fontWeight: 400,
		fontFamily: 'Roboto Flex, sans-serif',
		align: 'center',
		color: '#5C5C5C'
	},
	xaxis: {
		categories: ['Perfil', 'Carteira'],
		labels: {
			formatter: (value) => {
				return `${value}%`
			},
		},
	},
	yaxis: {
		max: 100,
		min: 0,
		stepSize: 10,
	},
	tooltip:{
		y: {
			formatter: (val) => {
        return `${parseFloat(val).toFixed(2)}%`
      },
		}
	},
	legend: {
		show: true,
		showForSingleSeries: true,
		showForNullSeries: true,
		showForZeroSeries: true,
		fontSize: '10px',
		fontFamily: 'Roboto Flex, sans-serif',
		fontWeight: 400,
	}
}

export const CARTEIRA_ALVOS_CHART_ID = '#chart-carteira-alvos'