import { InputHandler } from 'concurrently'

export default {
	props: {
		model: Array,
		options: Array,
		label: String,
		submitfc: Function,
	},

	emmits: ['changed'],

	data() {
		return {
			valueShown: Object,
			optionsList: Array,
			stringOptions: String,
			initialModel: this.model,
			changed: false,
		}
	},

	methods: {
		updateValueShown(arr) {
			if (!arr) return
			this.emitEventChange(arr)

			if (arr?.length == 0) return (this.valueShown = {})

			const active = arr[0]?.label
			const qnt = arr?.length - 2
			const active2 = arr[1]?.label
			this.valueShown = { active, qnt, active2 }
		},

		emitEventChange(value) {
			this.$emit('changed', value)
		},

		filterFn(val, update, options) {
			if (val === '') {
				return update(() => {
					this.optionsList = options
				})
			}

			update(() => {
				const needle = val.toLowerCase()
				this.optionsList = options.filter(
					(v) => v.label.toLowerCase().indexOf(needle) > -1
				)
			})
		},

		clearOptions() {
			while (this.model.length) {
				this.model.pop()
			}

			this.changed = true
			this.valueShown = {}
		},

		selectAllOptions() {
			let selectedList = []

			this.optionsList.forEach((opt) => {
				this.model.push(opt)
				selectedList.push(opt)
			})

			this.changed = true
			this.valueShown = {
				active: this.model[0].label,
				active2: this.model[1]?.label,
				qnt: this.model.length - 1,
			}
		},

		handleCloseDialog() {
			const initial = this.initialModel.map((obj) => obj.value).join(', ')
			const current = this.model.map((obj) => obj.value).join(', ')

			if (initial !== current || this.changed) {
				this.submitfc()
			}
		},
	},

	watch: {
		model: {
			handler(value) {
				this.updateValueShown(value)
			},
			deep: true,
		},
	},

	mounted() {
		this.updateValueShown(this.model)
	},

	template: html`
		<q-select
			:outlined="true"
			multiple
			dense
			use-input
			v-model="model"
			:label="label"
			:options="optionsList"
			@filter="(val, update) => filterFn(val, update, options)"
			@update:model-value="(value) => updateValueShown(value)"
			@popup-hide="handleCloseDialog">
			<template v-slot:option="{ itemProps, opt, selected, toggleOption }">
				<q-item v-bind="itemProps">
					<q-item-section>
						<q-item-label> {{ opt.label }} </q-item-label>
					</q-item-section>

					<q-item-section side>
						<q-toggle
							:model-value="selected"
							@update:model-value="toggleOption(opt)"></q-toggle>
					</q-item-section>
				</q-item>
			</template>

			<template v-slot:selected>
				<div class="flex gap-8 flex-wrap">
					<q-badge
						v-if="valueShown.active"
						rounded
						class="p-8"
						color="primary"
						outline
						:label=" valueShown.active" />
					<q-badge
						v-if="valueShown.active2"
						rounded
						class="p-8"
						color="primary"
						outline
						:label=" valueShown.active2" />
					<q-chip v-if="valueShown.qnt >= 1">+{{ valueShown.qnt }}</q-chip>
				</div>

				<!-- 	<q-btn
					:class="{ '!opacity-100': model.length }"
					class="!right-[9.5rem]"
					color="primary"
					label="Limpar"
					@click="clearOptions" /> -->
			</template>
		</q-select>
	`,
}
