const { ref } = Vue;
import sendEmail from "../mixins/sendEmail.js";
import ModalHeader from "./ModalHeader.js";

const instancia = {
	template: document.getElementById("modal_send_email"), // templates/home
	mixins: [sendEmail],

	emits: [],

	components: {
		ModalHeader,
	},

	props: {
		isFundacao: Boolean,
	},

	mounted() {
		GLOBAL.addRequiredLabel(document.getElementById("form_send_email"));
	},

	setup(props, ctx) {},

	delimiters: ["{*", "*}"], //delimitadores pra n conflitar com o django
};

export default instancia;
