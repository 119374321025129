const { errorDefault } = GLOBAL
export function useApi() {
	async function query(url, params = {}, options = {}) {
		NProgress.start()

		try {
			const { data } = await axios.get(url, { params, ...options })

			return data
		} catch (e) {
			errorDefault(e)
		} finally {
			NProgress.done()
		}
	}

	async function mutation(url, method, body = {}, params = {}, options = {}) {
		NProgress.start()

		try {
			const { data } = await axios[method](url, body, { params, ...options })

			return data
		} catch (e) {
			errorDefault(e)
		} finally {
			NProgress.done()
		}
	}

	return {
		query,
		mutation,
	}
}
