export default {
	props: ["editando"],
	emits: ["enviar", "cancelar"],

	data() {
		return {
			data: {}
		}
	},

	watch: {
		editando() {
			if (this.editando === false) {
				this.data = {}
				this.$refs.textarea.value = ""
				this.$refs.data.value = ""
			}
		}
	},


	mounted() {
		GLOBAL.initFlatpickr(this.$refs.data)

	},

	methods: {
		handleChange({ target }) {
			const value = target.value
			this.data[target.getAttribute("name")] = value.trim()

		},
		prevent(e) {
			console.log("aaaaaaaaaaa")
			e.preventDefault()
		},

		enviar() {
			this.$emit("enviar", this.data)
		},

		cancelar() {
			this.$emit("cancelar")
		},


		onBeforeEnter(el) {
			gsap.set(el, {
				height: 0,
			})
		},


		onEnter(el, done) {
			gsap
				.timeline({ ease: "power1", delay: 0 })
				.to(el, {
					height: "auto",
					duration: 0.2
				})
				this.$refs.new_task.scrollIntoView({ behavior: 'smooth', block: 'start' })

			done()
		},


	},

	template: `
		<Transition @before-enter="onBeforeEnter" @enter="onEnter">
			<div class="p-16 border !h-[18.75rem] border-primary-pure rounded-md new-task-el overflow-hidden " ref="new_task">
				<p class="text-primary-pure  font-medium mb-8">Nova Tarefa</p>
				<form class="flex flex-col items-center gap-8 " @change="handleChange" @submit="prevent">
		
					<div class="flex-1 flex flex-col gap-4 w-full">
						<label class="label">Descrição</label>
						<textarea ref="textarea" name="descricao" class="w-full p-10 input-text h-[5rem]"></textarea>
					</div>
		
					<div class="flex-1 flex flex-col  w-full">
						<label class="label">Prazo Final</label>
						<input ref="data" type="date" name="deadline" placeholder="Escolha o Prazo Final"
							class="w-full p-10 input-text">
					</div>
		
					<div class=" flex justify-between mt-8 w-full">
						<button type="button" class="btn secondary" @click="cancelar">Cancelar</button>
						<button type="button" class="btn" @click="enviar">Enviar</button>
					</div>
		
				</form>
			</div>
		</Transition>

	`
}