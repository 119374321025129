const { ref, mounted } = Vue;

export default {
	emits: [],

	props: {
		name: String,
		politica: Number,
		patrimonio: Number,
		clientes: Array,
		colors: Array,
		open: Function,
	},

	setup(props, ctx) {
		const arrow_click = ref(`arrow_click-${props.name}`);
		const politicaAtendida = ref("");
		const patrimonioFormated = (patrimonio) => {
			if (patrimonio == "-") return patrimonio;
			if (patrimonio == "0") return "-";
			const value = FormatREALVirgula(patrimonio);
			return value;
		};
		const politicaId = (bubble) => {
			switch (bubble) {
				case "SAI/Cliente":
					return "0";
				case "SAI":
					return "1";

				case "N/A":
					return "2";

				case "Consultor/Gestor":
					return "3";
				default:
					return "";
			}
		};

		return {
			arrow_click,
			patrimonioFormated,
			politicaId,
			politicaAtendida,
		};
	},

	mounted() {
		setTimeout(() => {
			console.log("a", this.props?.name);
		}, 2000);
		this.politicaAtendida.value = this.politicaId(this.props?.name);
		console.log("a", this.politicaAtendida.value, this.props?.name);
	},

	template: html`
		<div class="flex flex-col gap-8 swiper-slide flex-0  ">
			<div
				class="rounded-md border border-neutral-30 p-16 h-[110px] max-h-[110px] relative card-bubble cursor-pointer "
				:bubble="name"
			>
				<span
					class="block absolute top-0 l-16 h-[6px] w-[32px]   rounded-br-md rounded-bl-md"
					:class="colors[0]"
				></span>
				<div class="flex gap-8 justify-between w-full items-center">
					<h1 class="font-medium tracking-widest text-xs" :class="colors[1]">
						{{name}}
					</h1>
					<div
						class="rounded-full h-24 w-24 bg-primary-10 flex items-center justify-center"
						:class="colors[1]"
					>
						<svg
							:class="arrow_click"
							
							class="w-24 h-24 arrow-bubble "
							:bubble="name"
							style="--cor-1:#2945D3"
						>
							<use xlink:href="#arrow_right_color"></use>
						</svg>
					</div>
				</div>

				<div
					class="flex justify-between mt-16 text-xs gap-4 font-semibold text-neutral-80"
				>
					<div>
						<p class="mb-6">Patrimônio (Mil)</p>
						<p class="text-sm font-medium text-neutral-70">
							R$
							<span class="text-sm text-neutral-100">{{patrimonio}}</span>
						</p>
					</div>
					<div>
						<p class="mb-6">Política atendida</p>
						<p class="text-sm font-medium text-neutral-70">{{politica}}%</p>
					</div>
				</div>
			</div>

			<div
				class="card-wrapper bg-neutral-10 flex flex-col gap-12 border border-neutral-30 p-16 h-[21.3rem] overflow-auto"
			>
				<div v-for="cliente in clientes" :key="cliente.id" v-show="cliente.Atendida== '0'">
					
                    <div
                    
				v-if="cliente.politica == '0'"
					class="card-cliente group"
					:data-nome="cliente.nome"
					:data-id="cliente.id"
					:data-politica="cliente.politica"
					@click="open"
				>
					<div class="flex justify-between">
						<p class="text-sm font-medium text-neutral-100 two-line">
							 {{cliente.sigla}}
						</p>
						<svg
							class="flex-shrink-0 w-16 h-16 transition-colors duration-300 svg-user"
							style="--cor-1:#D4D5D9"
						>
							<use xlink:href="#icon_users"></use>
						</svg>
					</div>

				   <p class="text-xs font-medium text-neutral-80">
                                Patrimônio (Mil)<span class="text-sm font-medium ml-14 text-neutral-70">R$ {{cliente.patrimonio_interno}}
                                    
                            </p>
   
					<div class="flex items-center gap-4">
						<svg
							class="flex-shrink-0 w-16 h-16 transition-colors duration-300 svg-user"
							style="--cor-1:#D4D5D9"
						>
							<use xlink:href="#icon_phone"></use>
						</svg>
						<p v-if="cliente.telefone != 'None'">{{ cliente.telefone}} </p>
					</div>

					<div class="flex items-center gap-4">
						<svg class="w-20 h-20" style="--cor-1:#D4D5D9">
							<use xlink:href="#icon_mail"></use>
						</svg>
						<p
							class="text-ellipsis whitespace-nowrap overflow-hidden  w-[200px]"
						>
						{{ cliente.email}} 
						</p>
					</div>
				</div>
                <div v-else class="hidden"></div>
			</div>  

				<svg class="hidden w-full h-16 my-16 md2:block">
					<use xlink:href="#icon_delimiter"></use>
				</svg>
			</div>  
				</div>

					
			</div>
		</div>
	`,
};
