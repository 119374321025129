export default {
	props: ['editando', 'isFundacao'],

	data() {
		return {
			novoContatoDados: {
				descricao: '',
				observacoes: ''
			},
		}
	},

	methods: {
		handleChange(event) {
			const target = event?.currentTarget
			const name = target?.getAttribute('name')
			this.novoContatoDados[name] = target?.innerText
		},
		

		closeAtendimento() {
			document
				.querySelectorAll('.novo-atendimento p[contenteditable]')
				.forEach((i) => (i.innerText = ''))
			this.$emit('closeAtendimento')
		},

		sendAtendimento() {
			this.$emit('sendAtendimento', this.novoContatoDados)
		},

		onBeforeEnter(el) {
			document.querySelector('.table-contato').scrollTop = 0

			gsap.set(el, {
				height: 0,
				overflow: 'hidden',
				scaleY: 0,
			})
		},
		onEnter(el, done) {
			console.log('enter')
			done()
			gsap.timeline({ ease: 'power1', delay: 0 }).to(el, {
				scaleY: 1,
				height: this.isFundacao ? '13.69rem' : '26.5rem',
				duration: 0.2,
				transformOrigin: 'top',
			})
		},
	},

	template: /* html */ `
	<Transition @before-enter="onBeforeEnter" @enter="onEnter">
	<div class="table-row editando novo-atendimento bg-white z-20 ">
		<p class="text-primary-pure font-medium mb-8 col-span-8">Novo Contato</p>

	
		<div class="flex flex-col gap-4 col-span-2">
			<label class="text-xs text-neutral-80 font-medium">Nome</label>
			<p class="row-item" name="nome" @input="handleChange" contenteditable="true">
			</p>
		</div>
		
		<div class="flex flex-col gap-4">
			<label class="text-xs text-neutral-80 font-medium">Telefone</label>
			<p class="row-item" name="telefone" @input="handleChange" contenteditable="true">
			</p>
		</div>
		<div class="flex flex-col gap-4">
			<label class="text-xs text-neutral-80 font-medium">Celular</label>
			<p class="row-item" contenteditable="true">
			</p>
		</div>
	
		<div class="flex flex-col gap-4 col-span-2">
			<label class="text-xs text-neutral-80 font-medium ">Email</label>
			<p class="row-item" name="email" contenteditable="true"  @input="handleChange">
			</p>
		</div>
			<div v-if="!isFundacao" class="row-item col-start-1 col-end-8 flex flex-col gap-4">
			<label class="text-xs text-neutral-80 font-medium">Descrição:</label>
		
		<q-editor
			v-model="novoContatoDados.descricao"
			js-ativo
			max-height="5rem"
			min-height="5rem"
			name="descricao"
			class="w-full  col-span-full "
			:dense="$q.screen.lt.md"
			:toolbar="[
				 ['unordered' ], 

			]"
			/>
					
		</div>
	<div v-if="isFundacao" class="row-item col-start-1 col-end-8 flex flex-col gap-4">
			<label class="text-xs text-neutral-80 font-medium">Observações:</label>
			<p name="observacoes" @input="handleChange" contenteditable="true" >
			</p>
		</div>
	
		<div v-else class="row-item col-start-1 col-end-8 flex flex-col gap-4">
			<label class="text-xs text-neutral-80 font-medium">Observações:</label>
			
				<q-editor
			v-model="novoContatoDados.observacoes"
			js-ativo
			max-height="3rem"
			min-height="3rem"
			name="observacoes"
			class="w-full  col-span-full "
			:dense="$q.screen.lt.md"
			:toolbar="[
				 ['unordered' ], 

			]"
			/>
			</p>
		</div>
	
	
		<div class="col-span-8 flex justify-between">
			<button class="btn secondary " @click="closeAtendimento">Cancelar</button>
			<button class="btn" @click="sendAtendimento">Salvar</button>
		</div>
	
	</div>
</Transition	>
	`,
}
