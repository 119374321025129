export const TEMPO_VENCIMENTO_CHARTS = [
	{
		key: 'data_pre',
		idChart: 'chartTempoVencimentoPre',
		idEl: '#chart-tempo-vencimento-pre',
		colors: ['#003769', '#FFBB0B'],
    title: 'Pré',
	},
	{
		key: 'data_ipca',
    idChart: 'chartTempoVencimentoIPCA',
    idEl: '#chart-tempo-vencimento-ipca',
    colors: ['#95FF8C', '#FFBB0B'],
    title: 'IPCA+',
	},
	{
		key: 'data_pos',
    idChart: 'chartTempoVencimentoPos',
    idEl: '#chart-tempo-vencimento-pos',
    colors: ['#8A96FF', '#FFBB0B'],
    title: 'Pós',
	}
]