export default {
	props: [
		'novaAgendaPolitica',
		'optionsAgendaPoliticas',
		'clienteAtivo',
		'isFundacao',
	],

	data() {
		return {
			data: {},
			userLogado: document.getElementById('usuario-logado').value,
		}
	},

	mounted() {
		this.initLibs()
		gsap
			.timeline()
			.set(this.$refs.nova_politica, {
				height: 0,
				overflow: 'hidden',
			})
			.to(this.$refs.nova_politica, {
				duration: 0.25,
				height: 'auto',
			})
			.to(this.$refs.nova_politica, { overflow: 'initial' }, '+=0.2')
	},

	unmounted() {},

	created() {
		console.log('created')
	},

	watch: {
		data: {
			handler() {
				_BIG('Nova politica')
				//console.log(this.data)
			},
			deep: true,
		},
	},

	methods: {
		handleChange(event) {
			const target = event.target
			const name = target.getAttribute('name')
			this.data[name] = target.value
			//console.log(`rolou alteracao na ${name}  = ${target.value}`)
		//	console.log(JSON.stringify(this.data))
		},

		initLibs() {
			const select = document.querySelector(
				'.nova-agenda-politicas-select-container select'
			)

			const choices = GLOBAL.initChoices(select, {
				searchEnabled: false,
				searchChoices: false,
				itemSelectText: 'Selecionar',
				noResultsText: 'Nada Encontrado...',
			})

			window.listOfChoices = [...window.listOfChoices, choices]
			const inputDate = document.getElementById('nova-politica-input-date')
			GLOBAL.initFlatpickr(inputDate)
		},

		closeAtendimento() {
			const inputDate = document.querySelector('#nova-politica-input-date')
			inputDate.value = ''
			const choices = GLOBAL.getChoicesActive(
				'select-nova-politica-modal',
				window.listOfChoices
			)
			choices.setChoiceByValue('')
			this.data = {}
			this.$emit('close')
		},

		createNewchoices() {
			const selects = document.querySelector(
				'.nova-agenda-politicas-select-container select'
			)
		},

		async sendPolitica() {
			if (Object.keys(this.data).length < 2) {
				GLOBAL.showToastify('⚠️ Preencha todos os valores antes de enviar')
				return
			}
			const data1 = this.data.data

			const dataFormated = moment(data1, 'DD/MM/YYYY').format()

		//	console.log('ANTES DE ENVIAR ADRIANO', this.userLogado)
			try {
				NProgress.start()
				const request = await axios.post(`/api/atendimentos/`, {
					...this.data,
					tipo: '1',
					data: dataFormated,
					usuario_responsavel: [this.userLogado],
					[this.isFundacao ? 'fundacao' : 'cliente_pf']: this.clienteAtivo.id,
				})

				GLOBAL.showToastify('✅ Enviado com sucesso')
				this.$emit('update')
				this.closeAtendimento()
			} catch (e) {
				GLOBAL.errorDefault(e, '❌ Ocorreu um erro ao Alterar')
			} finally {
				NProgress.done()
			}
		},

		onBeforeEnter(el) {
			
			gsap.set(el, {
				height: 0,
			})
		},

		onEnter(el, done) {
			console.log(el, done)
			gsap.timeline({ ease: 'power1', delay: 0 }).to(el, {
				height: '19rem',
				duration: 0.2,
			})

			// done()
		},
	},

	template: /* html */ `
	
				<li ref="nova_politica" class="p-8 border border-primary-pure rounded-md">
					<p class="text-primary-pure font-medium mb-8">Nova Politica</p>

					<form id="form-nova-politica" class="flex items-center gap-8 " @change="handleChange">
						<div class="flex-1 font-medium text-sm leading-100 text-neutral-70 border border-neutral-30 rounded-md h-40">
							<input type="date" name="data" id="nova-politica-input-date" placeholder="Escolha a data" class="w-full p-10">
						</div>
			
						<div class="select-container w-160 h-40 nova-agenda-politicas-select-container">
							<select js-choices name="modalidade_cliente_pf" id="select-nova-politica-modal">
								<option value="" selected disabled placeholder>Selecione uma politica</option>
								<option v-for="([key,value]) in optionsAgendaPoliticas" :value="key">{{value}}</option>
							</select>
						</div>
			
			
					</form>
			
					<div class="col-span-8 flex justify-between mt-8">
						<button class="btn secondary" @click="closeAtendimento">Cancelar</button>
						<button class="btn" @click="sendPolitica">Salvar</button>
					</div>
				</li>

	`,
}
