function getSemestralChartColors({ dataPointIndex }) {
	switch (dataPointIndex) {
		case 0:
			return '#840505'
		case 1:
			return '#D83232'
		case 2:
			return '#FF8787'
		case 3:
			return '#6D6F71'
		case 4:
			return '#B8BCBF'
	}
}

export const SEMESTRAL_CHART_CURRENT_DEFAULT_VALUE = {
	num_clientes_ultra_high: 0,
	num_clientes_private: 0,
	num_clientes_premium: 0,
	num_clientes_na: 0,
	num_clientes_distribuicao: 0,
	semestre: '',
}

export const SEMESTRAL_CHART_DEFAULT_OPTIONS = {
	series: [
		{
			name: 'Atual',
			data: [],
		},
		{
			name: 'Sel.',
			data: [],
		},
	],
	chart: {
		type: 'bar',
		height: 180,
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	stroke: {
		curve: 'straight',
	},
	xaxis: {
		labels: {
			show: true,
		},
		type: 'category',
		categories: [
			'Sel. - Atual',
			'Sel. - Atual',
			'Sel. - Atual',
			'Sel. - Atual',
			'Sel. - Atual',
		],
	},
	colors: [getSemestralChartColors],
	legend: {
		show: false,
	},
}
