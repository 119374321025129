import {
	VOLATILIDADE_CHART_OPTIONS,
	VOLATILIDADE_CHART_ID_1M,
	VOLATILIDADE_CHART_ID_3M
} from '../constants/volatilidade_charts'

import { useCharts } from './useCharts'

const { createChart } = useCharts()

export function useVolatilidade() {
	function createVolatilidadeChart(
	{	volatilidades,
		rentabilidades,
		labels,
		periodo,
		message,
	estatisticas}
	) {

		const max_value = Math.max(...volatilidades, ...rentabilidades)
		const maxObj = max_value < periodo?.objetivo ? {
			max: periodo?.objetivo
		} : null
		const options = {
			...VOLATILIDADE_CHART_OPTIONS,
			noData: {
				text: !volatilidades.length ? 'Volatididade não encontrada na data solicitada' :  'Dados não encontrados',
				align: 'center',
				verticalAlign: 'middle',

				style: {
					color: undefined,
					fontSize: '14px',
					fontFamily: undefined,
				},
			},
			chart: {
				width: volatilidades.length > 80
					? `${volatilidades.length * 12}px`
					: '100%',
				heigth: 400,
				id:periodo?.periodo === '1M' ? VOLATILIDADE_CHART_ID_1M : VOLATILIDADE_CHART_ID_3M ,
				...VOLATILIDADE_CHART_OPTIONS.chart,
			},
			series: [
				{
					name: 'Rend. Diário',
					type: 'column',
					data: rentabilidades,
				},
				{
					name: periodo?.periodo,
					type: 'line',
					data: volatilidades,
				},
		
			],
			xaxis: {
				type: 'category',
				categories: labels,
				labels: {
					show: true,
					rotate: 0,
					style: {
					
						fontSize: '10px',
					
						cssClass: 'apexcharts-xaxis-label',
				},
				},
			},

			yaxis: {
				...maxObj,
				forceNiceScale: true,
				labels: {
					formatter: function (y) {
						if (typeof y !== 'undefined') {
							return y + '%'
						}
						return y
					},
				},
			},

			annotations: {
				yaxis: [
					{
						y: Number(periodo?.objetivo) || null,
						strokeDashArray: 3,
						borderColor: '#6D6F71',
						label: {
							show: false,
							position: 'bottom',
							borderColor: '#000',
							style: {
								color: '#000',
								background: '#fff',
								padding: {
									bottom: 4,
								},
							},
							text:'',
						},
					},
				],
			},

			tooltip: {
				shared: true,
				intersect: false,
				followCursor: true,
				custom: handleCustomTooltip(
					volatilidades,
					rentabilidades,
					labels,periodo, estatisticas
				),
			},
		}
		
		if(periodo?.periodo === '1M' ){
			createChart(VOLATILIDADE_CHART_ID_1M, options)
		}else{
			createChart(VOLATILIDADE_CHART_ID_3M, options)

		}
	}

	function handleCustomTooltip(
		volatilidades,
		rentabilidades,
		labels,periodo, estatisticas
	) {
		return ({ series, seriesIndex, dataPointIndex }) => {
			const volatilidade = volatilidades[dataPointIndex]
			const rentabilidade_diaria = rentabilidades[dataPointIndex]
			const data = labels[dataPointIndex]
			const volatilidadeTexto = volatilidade ? `${volatilidade}%` : '-'
			const rentabilidadeTexto = rentabilidade_diaria
				? `${rentabilidade_diaria}%`
				: '-'

			return `
			<div class="arrow_box p-8 bg-white"
			>
				<p class="font-medium text-sm text-neutral-100">${data}</p>
				${`<p class="text-neutral-70 font-normal text-xs">Volatilidade: ${volatilidadeTexto}</p>
				<p class="text-neutral-70 font-normal text-xs">Rentabilidade: ${rentabilidadeTexto}</p>
				<p class="text-neutral-70 font-normal text-xs">Objetivo: ${periodo?.objetivo || 'Sem Perfil'}</p>
				<p class="text-neutral-70 font-normal text-xs">Limite Superior: ${periodo?.limite_superior || 'Sem Perfil'}</p>
				<p class="text-neutral-70 font-normal text-xs">Limite Inferior: ${periodo?.limite_inferior || 'Sem Perfil'}</p>
				<p class="text-neutral-70 font-normal text-xs">Média: ${estatisticas.media || '-'}</p>
				<p class="text-neutral-70 font-normal text-xs">Mediana: ${estatisticas?.mediana || '-'}</p>
				`}
			</div>
		`
		}
	}

	return {
		createVolatilidadeChart,
	}
}
