export default {
	props: ['type'],

	setup(props) {
		return {
			avatarColor: GLOBAL.generateAvatarColor(props.type),
		};
	},
	

	template: /* html */ `
		<div class="inline-flex justify-center">
			<svg class="h-40 w-40" :style="avatarColor">
				<use xlink:href="#icon_person"></use>
			</svg>
		</div>
	`,
}
