import { useClientes } from '../composables/useClients.js'
import {
	SEMESTRAL_CHART_CURRENT_DEFAULT_VALUE,
	SEMESTRAL_CHART_DEFAULT_OPTIONS,
} from '../constants/clients.js'
import acessos from '../mixins/acessos'
import TableAtendimento from '../components/TableAtendimento.js'
import AvatarPersona from '../components/AvatarPersona.js'
import ModalResumo from '../components/ModalResumo.js'
import ModalFiltros from '../components/ModalFiltros.js'
import ModalEmissorFGCAtivos from '../components/ModalEmissorFGCAtivos.js'

const { api, formatReal2 } = GLOBAL

const {
	createPatrimonioClienteTable,
	calculaSomaTotal,
	formatColumnsToTable,
	onTableFetchServer,
} = useClientes()

NProgress.configure({ trickleSpeed: 40, trickle: true, minimum: 0.65 })

export default {
	mixins: [acessos],

	components: {
		TableAtendimento,
		AvatarPersona,
		ModalResumo,
		ModalFiltros,
		ModalEmissorFGCAtivos,
	},

	data() {
		return {
			principais_clientes_cliente_pf:
				global_tables.principais_clientes_cliente_pf,
			dados_semestre_clientes: global_tables.dados_semestre_clientes,
			dados_semestre_pl: global_tables.dados_semestre_pl,
			lista_segmentos: global_tables.lista_segmentos,
			segmentos: [],
			total: {},
			maxNumClientes: null,
			maxPl: null,
			haveModalResumo: true,
			haveFilterEmissor: false,
			urlParams: new URLSearchParams(window.location.search),
			url_filters: '',
			tabela: null,
			limpar: false,
			somaTotal: [],
			temAtivos: false,
			chartSemestral: undefined,
			chartNumClientes: undefined,
		}
	},

	methods: {
		geraTemplateTablePatrimonioCliente(search) {
			console.log({params:this.urlParams})
			this.tabela = createPatrimonioClienteTable(search, {
				foraFGC: this.urlParams.get('fora_fgc') == 'Fora do FGC hoje',
				foraFgcGeral: this.urlParams.get('fora_fgc') == 'Sim',
				foraFgcNao: this.urlParams.get('fora_fgc') == 'Não',
				ativos: this.urlParams.get('ativos'),
				emissores: this.urlParams.get('emissores'),
				by: document.querySelector('.select-paginate-by').value,
				somaTotal: this.somaTotal,
				isFilter: this.urlParams.size > 1
			})

			this.tabela.on('rowClick', (...args) => {
				const dados = args[1]

				const id = dados.cells[0].data
				const politica = dados.cells[1].data

				this.openModal({}, id, politica)
			})

			this.tabela.render(document.querySelector('#table_clientes_patrimonio'))

			return this.tabela
		},

		handleChangeSemestres() {
			const form = document.querySelector('#form_selects_novo_gerencial_sap')
			form.submit()
		},

		openModal({ currentTarget }, idActive, politicaActive) {
			const id = idActive || currentTarget?.getAttribute('data-id')
			const politica =
				politicaActive || currentTarget?.getAttribute('data-politica')
			console.log({ currentTarget })
			this.$refs.modalResumo.OpenModalResumo({
				id,
				politica,
				atendimentoID: null,
			})
		},

		generateColor(typeUser, type) {
			switch (typeUser) {
				case 0:
					return `${type}: #840505`
				case 1:
					return `${type}: #D83232`
				case 2:
					return `${type}: #FF8787`
				case 3:
					return `${type}: #6D6F71`
				default:
					return `${type}: #B8BCBF`
			}
		},

		fillNumClientsChart(data) {
			let current = SEMESTRAL_CHART_CURRENT_DEFAULT_VALUE

			if (data[0]) {
				current = {
					...data[0],
				}
			}

			const numbers = []

			numbers.push(current.num_clientes_distribuicao)
			numbers.push(current.num_clientes_private)
			numbers.push(current.num_clientes_ultra_high)
			numbers.push(current.num_clientes_premium)
			numbers.push(current.num_clientes_na)
			numbers.push(
				...this.segmentos.map((numClientes) => numClientes?.n_clientes)
			)
			this.maxNumClientes = numbers.sort((a, b) => b - a)[0]

			const series = [
				{
					name: `Semestre ${current.semestre}`,
					data: [
						current.num_clientes_ultra_high,
						current.num_clientes_private,
						current.num_clientes_premium,
						current.num_clientes_distribuicao,
						current.num_clientes_na,
					],
				},
				{
					name: 'Atual',
					data: this.segmentos.map((numClientes) => numClientes.n_clientes),
				},
			]

			this.chartNumClientes.updateOptions({
				series,
				yaxis: {
					tickAmount: 4,
				},
			})
		},

		fillSemestralChart(data) {
			let current = SEMESTRAL_CHART_CURRENT_DEFAULT_VALUE

			if (data[0]) {
				current = {
					...data[0],
				}
			}

			const series = [
				{
					name: `Semestre ${current.semestre}`,
					data: [
						current.pl_ultra_high,
						current.pl_private,
						current.pl_premium,
						current.pl_distribuicao,
						current.pl_na,
					],
				},
				{
					name: 'Atual',
					data: this.segmentos.map((segmento) =>
						segmento.patrimonio.replaceAll('.', '')
					),
				},
			]

			this.chartSemestral.updateOptions({
				series,
				yaxis: {
					tickAmount: 4,
					labels: {
						formatter: (value) => formatReal2(value),
					},
				},
			})
		},

		async downloadGerencialSapTable() {
			try {
				window.myLoader().show()
				const initSearch = window.location.search.replace('?', '')
				this.urlParams = new URLSearchParams(initSearch)

				let search = '?'
				search += initSearch
				search = search.replaceAll('paginate_by', 'nada')
				search = search.replaceAll('page', 'nada')
				search += '&paginate_by=9999'

				const ativos = this.urlParams.get('ativos')
				const emissores = this.urlParams.get('emissores')
				const fora_fgc = this.urlParams.get('fora_fgc')

				if (ativos) {
					this.temAtivos = true
				} else {
					this.temAtivos = false
				}
				const request = await api.get(
					`api/tabela_clientepf/principais_clientes/${search}`
				)

				const arr = request.data?.results.map((item) => {
					let modelo_negocio
					if(item.consultoria) modelo_negocio = 'Consultoria'
					if(item.gestao) modelo_negocio = 'Gestão'
					if(item.distribuicao) modelo_negocio = 'Distribuição'
					let obj = {
						nome: item.nome,
						patrimonio_interno: GLOBAL.formatReal2(item.patrimonio_interno),
						patrimonio_externo: GLOBAL.formatReal2(item.patrimonio_externo),
						modelo_negocio, 
					}
					console.log({modelo_negocio})
					if (fora_fgc) {
						if (fora_fgc === 'Sim') {
							obj = {
								...obj,
								emissores_fora_fgc_geral: item.emissores_fora_fgc_geral
									.map((i) => i[0])
									.join(', '),
								emissores_fora_fgc_geral_valor: item.emissores_fora_fgc_geral
									.map((i) => GLOBAL.formatReal2(i[1]))
									.join(', '),
							}
						} else if (fora_fgc === 'Fora do FGC hoje') {
							obj = {
								...obj,
								emissores_fora_fgc_hj: item.emissores_fora_fgc_hj
									.map((i) => i[0])
									.join(', '),
								emissores_fora_fgc_hj_valor: item.emissores_fora_fgc_hj
									.map((i) => GLOBAL.formatReal2(i[1]))
									.join(', '),
							}
						}
					} else {
						if (ativos) {
							obj = {
								...obj,
								ativos: item.ativo_nome.join(','),
								porcentagem_ativo: item.ativo_nome_porcentagem.join(', '),
								porcentagem_ativo_rf: item.ativo_nome_porcentagem_rf.join(', '),
								valor_ativo: item.ativo_nome_valor
									.map((i) => GLOBAL.formatReal2(i))
									.join(','),
							}
						}
						if (emissores) {
							obj = {
								...obj,
								emissores: item.ativo_emissor.join(', '),
								porcentagem_emissor: item.ativo_emissor_porcentagem.join(', '),
								porcentagem_emissor_rf:
									item.ativo_emissor_porcentagem_rf.join(', '),
								valor_emissor: item.ativo_emissor_valor
									.map((i) => GLOBAL.formatReal2(i))
									.join(', '),
							}
						}
					}

					return obj
				})

				GLOBAL.tableToExcel('', 'teste', arr)
				window.myLoader().hide()
			} catch (error) {
				console.error(error)
			}
		},

		openFiltros() {
			modal_padrao_vue.openModal('criar_filtros')
		},

		async getBigNumbers(search) {
			try {
				const { data } = await api.get(
					'/api/clientepf/dados_politica/?' + search.replace('?', '')
				)

				if (!data) return

				this.segmentos = data
					?.map((i, index) => {
						if (index !== 0) {
							return {
								...i,
								patrimonio: GLOBAL.FormatREAL(String(i.patrimonio)),
							}
						}
					})
					.filter((i) => i !== undefined)

				this.total = {
					...data[0],
					patrimonio: GLOBAL.FormatREAL(String(data[0].patrimonio)),
				}
			} catch (error) {
				console.error(error)
			}
		},

		generateSemestralChart() {
			const semestralOptions = {
				...SEMESTRAL_CHART_DEFAULT_OPTIONS,
				dataLabels: {
					enabled: false,
				},
				yaxis: {
					show: true,
					decimalsInFloat: 2,
					labels: {
						formatter: (value, i) => {
							return GLOBAL.FormatREAL(String(value))
						},
					},
				},
				plotOptions: {
					bar: {
						borderRadius: 6,
						borderRadiusApplication: 'end',
						borderRadiusWhenStacked: 'last',
						columnWidth: '75%',
					},
				},
			}

			this.chartSemestral = new ApexCharts(
				document.querySelector('#chart_semestral'),
				semestralOptions
			)

			this.chartSemestral.render()
		},

		generateNumClientsChart() {
			const numClientesOptions = {
				...SEMESTRAL_CHART_DEFAULT_OPTIONS,
				dataLabels: {
					enabled: false,
					textAnchor: 'middle',
					formatter: function (val) {
						if (val <= 0) return
						return val
					},
					background: {
						enabled: true,
						foreColor: '#840505',
						dropShadow: {
							enabled: true,
							top: 1,
							left: 1,
							blur: 1,
							color: '#000',
							opacity: 0.1,
						},
					},
					offsetY: 15,
				},
				yaxis: {
					max: 150,
				},
				plotOptions: {
					bar: {
						borderRadius: 6,
						borderRadiusApplication: 'end',
						borderRadiusWhenStacked: 'last',
						columnWidth: '60%',
					},
				},
			}

			this.chartNumClientes = new ApexCharts(
				document.querySelector('#chart_numClientes1'),
				numClientesOptions
			)

			this.chartNumClientes.render()
		},
	},

	async mounted() {
		this.url_filters = window.location.search
		this.urlParams = new URLSearchParams(this.url_filters)

		const elPl = document.querySelector('#select_semestre_pl')
		const isSuperUser = document.querySelector('#superusuario').value
		const params = isSuperUser === 'True' ? '?usuario_responsavel=todos' : '?'
		const search = this.url_filters?.length ? this.url_filters : params
		const table = document.querySelector('#table_clientes_patrimonio')
		const paginatedBySelect = document.querySelector('.select-paginate-by')

		if (elPl) elPl.value = this.urlParams.get('semestre_pl')

		if (document.querySelector('#select_semestre_clientes')) {
			document.querySelector('#select_semestre_clientes').value =
				this.urlParams.get('semestre_clientes')
		}

		if (document.querySelector('select[name="politica"]')) {
			document.querySelector('select[name="politica"]').value =
				this.urlParams.get('politica')
		}
		if (document.querySelector('select[name="faixa_ple"]')) {
			document.querySelector('select[name="faixa_ple"]').value =
				this.urlParams.get('faixa_ple')
		}

		this.generateSemestralChart()

		this.generateNumClientsChart()

		if (this.urlParams.get('ativos')) {
			this.somaTotal = await calculaSomaTotal(search)
		}
		window.tabela = this.geraTemplateTablePatrimonioCliente(search)

		await this.getBigNumbers(search)

		this.fillNumClientsChart(this.dados_semestre_clientes)

		this.fillSemestralChart(this.dados_semestre_pl)

		const gridSearch = document.querySelector('.gridjs-search')

		if (gridSearch) {
			document.querySelector('.gridjs-search-input').style.paddingLeft =
				'10px !important'
			gridSearch.insertAdjacentHTML(
				'afterbegin',
				'<div class="absolute bottom-0 top-0 left-8 mx-0 my-auto h-fit"><svg class="w-24 h-20"><use xlink:href="#icon_search"></use></svg></div>'
			)
		}

		if (this.urlParams.get('ativos')) {
			this.somaTotal = await calculaSomaTotal(search)

			table.classList.add('ativos')
		} else {
			table.classList.remove('ativos')
		}

		paginatedBySelect.addEventListener('change', async (e) => {
			await window.tabela?.config.plugin.remove('pagination')

			await window.tabela?.config.plugin.remove('search')

			window.tabela
				?.updateConfig({
					pagination: {
						enabled: true,
						limit: e.target.value,
						server: {
							url: (urlApi, page, limit) => {
								return `${urlApi}&page=${++page}&paginate_by=${limit}${search.replace(
									'?',
									'&'
								)}`
							},
						},
					},
				})
				.forceRender()
		})
	},

	delimiters: ['{*', '*}'], //delimitadores pra n conflitar com o django
}
