import {
	RETORNO_CHART_OPTIONS,
	RETORNO_CHART_ID,
	
} from '../constants/retorno_charts'

import { useCharts } from './useCharts'

const { createChart } = useCharts()

export function useRetorno() {
	function createRetornoChart(
	{	
		cdi,
			labels,
			cotas,
			rentabilidades
		}
	) {

		const options = {
			...RETORNO_CHART_OPTIONS,
			noData: {
				text: 'Dados não encontrados',
				align: 'center',
				verticalAlign: 'middle',

				style: {
					color: undefined,
					fontSize: '12px',
					fontFamily: undefined,
				},
			},
			chart: {
				width:  '100%',
				heigth: 400,
				id:'chart-retorno' ,
				...RETORNO_CHART_OPTIONS.chart,
			},
			series: [
			
				{
					name: 'CDI',
					data: cdi,
				},

				{
					name: 'Rent. Acumulada',
					data: cotas,
				},
		
			],
			xaxis: {
				type: 'category',
				tickAmount: 12,
				categories: labels,
				labels: {
					show: true,
					rotate: 0,
					style: {
						fontSize: '10px',
						cssClass: 'apexcharts-xaxis-label',
				},
				},
			},

			yaxis: {
				forceNiceScale: true,
				labels: {
					formatter: function (y) {
						if (typeof y !== 'undefined') {
							return y + '%'
						}
						return y
					},
				},
			},


			tooltip: {
				shared: true,
				intersect: false,
				followCursor: true,
				custom: handleCustomTooltip(
					cdi,
					labels,
					cotas, rentabilidades
				),
			},
		}
		
			createChart(RETORNO_CHART_ID, options)
		
	}

	function handleCustomTooltip(
		
		cdi,
		labels,
		cotas, rentabilidades
	) {
		return ({  dataPointIndex }) => {
			const data = labels[dataPointIndex]
			
			const _cdi = cdi[dataPointIndex]
			const cdiTexto = _cdi ? `${_cdi}%` : '-'
			
			const rentabilidade = rentabilidades[dataPointIndex]
			const rentabilidadeTexto = rentabilidade ? `${rentabilidade}%` : '-'

			const acumulada = cotas[dataPointIndex]
			const acumuladaTexto = acumulada ? `${acumulada}%` : '-'
			

			return `
			<div class="arrow_box p-8 bg-white"
			>
				<p class="font-medium text-sm text-neutral-100">${data}</p>
				${`<p class="text-neutral-70 font-normal text-xs">CDI - Últimos 12 meses: ${cdiTexto}</p>
				<p class="text-neutral-70 font-normal text-xs">Rentabilidade: ${rentabilidadeTexto}</p>
				<p class="text-neutral-70 font-normal text-xs">Rentabilidade Acumulada: ${acumuladaTexto}</p>
				
				`}
			</div>
		`
		}
	}

	return {
		createRetornoChart,
	}
}
