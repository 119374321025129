export function useConcentracao() {
	function buildMarginsLines(chartElementSelector, margins) {
		const chartElement = document.querySelector(chartElementSelector)

		const oldLines = chartElement.querySelectorAll('.margins-chart-line')

		if (oldLines?.length) {
			oldLines.forEach((line) => {
				line.remove()
			})
		}

		const chartElementRect = chartElement.getBoundingClientRect()
		const seriesWrapper = chartElement.querySelector('.apexcharts-series')
		const bars = Array.from(seriesWrapper?.getElementsByTagName('path'))
		const grid = chartElement.querySelector('.apexcharts-grid')
		const gridRect = grid.getBoundingClientRect()
		const gridWidth = gridRect.width
		const offset = gridRect.left - chartElementRect.left

		bars.forEach((bar, index) => {
			const margin = margins[index]

			if (!margin) return

			const line = document.createElement('div')
			const start =
				gridWidth * GLOBAL.convertPercentageInDecimal(margin[0]) + offset
			const end =
				gridWidth * GLOBAL.convertPercentageInDecimal(margin[1]) + offset
			const barRect = bar.getBoundingClientRect()

			line.style.top = `${
				barRect.top - chartElementRect.top + barRect.height + 4
			}px`
			line.style.left = `${start}px`
			line.style.width = `${end - start}px`
			line.setAttribute('class', 'margins-chart-line')

			chartElement.appendChild(line)
		})
	}

	function serializeLimites(limite_inferior, limite_superior) {
		const limiteInferiorSerialized =
			parseFloat(limite_inferior) < 0 ? '0' : limite_inferior
		const limiteSuperiorSerialized =
			parseFloat(limite_superior) > 100 ? '100' : limite_superior

		return [limiteInferiorSerialized, limiteSuperiorSerialized]
	}

	return {
		buildMarginsLines,
		serializeLimites,
	}
}
