const { ref, watch, computed } = Vue;

const { api, isFundacao } = GLOBAL;

window.timingSearchFundacao = null;

export default {
	emits: ["click:item"],

	props: {},

	setup(props, ctx) {
		const list = ref([]);
		const loading = ref(false);
		const inputValue = ref("");

		function debounceFunction(fn, wait = 300) {
			return (...args) => {
				clearTimeout(window.timingSearchFundacao);
				window.timingSearchFundacao = setTimeout(() => fn(...args), wait);
			};
		}

		async function search(e) {
			loading.value = true;

			const url = isFundacao()
				? `/api/fundacao/?search=${inputValue.value}`
				: `/api/clientepf/?search=${inputValue.value}`;

			try {
				const request = await api.get(url);
				const data = await request.data;
				console.log(data);
				list.value = data;
			} catch (error) {
			} finally {
				loading.value = false;
			}
		}

		async function handleClickItem(id) {
			ctx.emit("click:item", id);
			list.value = [];
			inputValue.value = "";
		}

		watch(
			() => inputValue,
			(v) => {
				if (!v) {
					list.value = [];
				}
			}
		);

		const inputClass = computed(() => ({
			"border-primary-pure": inputValue.value.length,
			"!w-[500px]": inputValue.value.length,
		}));

		return {
			debounceFunction,
			search,
			list,
			loading,
			inputValue,
			handleClickItem,
			inputClass,
		};
	},

	template: html`
		<div class="input-icon relative">
			<input
				v-model="inputValue"
				class="input-text h-48 w-[20.88rem]"
				:class="inputClass"
				placeholder="Pesquise.."
				js-ativo
				type="text"
				@keydown="(e) => debounceFunction(search)(e)"
			/>

			<svg
				v-if="inputValue"
				@click="inputValue = '' "
				class="!w-32 !h-32 cursor-pointer"
				style="--cor-1: rgba(109,111,113,1);"
			>
				<use xlink:href="#icon_close"></use>
			</svg>
			<svg v-else>
				<use xlink:href="#icon_search"></use>
			</svg>

			<section
				class="absolute bottom-[calc(-200px_+_3px)] h-[200px] left-0 z-50 w-full bg-white shadow-sm rounded-b-md border border-primary-pure border-t-transparent overflow-y-auto overflow-x-hidden"
				v-show="inputValue"
			>
				<Transition name="fade" mode="out-in">
					<p
						class="text-neutral-60 text-center p-16 pointer-events-none"
						v-if="loading"
					>
						Carregando...
					</p>

					<ul v-else-if="list.length > 0">
						<li
							class="px-16 flex gap-4 py-12 hover:bg-neutral-20 cursor-pointer"
							@click="handleClickItem(item.id)"
							v-for="item in list"
							:key="item.id"
						>
							<p :title="item.nome" class="one-line">
								<template v-if="item.sigla">
									({{item.sigla}}) {{item.nome}}
								</template>
								<template v-else> {{item.nome}} </template>
							</p>
						</li>
					</ul>

					<p
						class="text-neutral-60 text-center p-16 pointer-events-none"
						v-else-if="list.length === 0 && !loading"
					>
						Sem resultados...
					</p>
				</Transition>
			</section>
		</div>
	`,
};
