export const RETORNO_CHART_OPTIONS = {
	chart: {
		height: 350,

		type: 'line',
		stacked: false,
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	stroke: {
		width: [2, 2],
		curve: 'straight',
	},
	plotOptions: {
		bar: {
			columnWidth: '50%',
		},
	},

	legend: {
		markers: {
			width: 20,
			height: 4,
			radius: 0,
		},
		horizontalAlign: 'left',
		
		itemMargin: {
			vertical: 20,
		},
	},

	fill: {
		opacity: [1, 1, 1],
		gradient: {
			inverseColors: false,
			shade: 'light',
			type: 'vertical',
			opacityFrom: 0.85,
			opacityTo: 0.55,
			stops: [0, 100, 100, 100],
		},
	},

	markers: {
		size: 0,
	},
	colors: ['#5a5568', '#6B0000'],


	yaxis: {
		title: {
			text: '',
		},
	},
}

export const RETORNO_CHART_ID = '#chart-retorno'
