import AvatarPersona from "./AvatarPersona"
import ConfirmModal from "./ConfirmModal";
import acessos from "../mixins/acessos";
const {
	isFundacao,
} = GLOBAL;
export default {
	emits: ["enviar",'open'],
	props: ["data"],
	mixins: [acessos],

	data() {
		return {
			editado: false,
			editando: false,
			taskdados: {},
			text: "Deseja concluir a tarefa",
			modalConfirm: false,
		}
	},

	components: {
		AvatarPersona,
		ConfirmModal
	},

	watch: {
		editando() {
			if (this.editando) {
				this.$refs.deadline.dispatchEvent(new Event("change"))
			}
		},
		taskdados: {
			handler() {
				_BIG("taskdados");
				console.log(this.taskdados);
			},
			deep: true,
		},
	},


	mounted() {
		if (this.$refs.deadline) GLOBAL.initFlatpickr(this.$refs.deadline)
	},

	methods: {
		getStatus(statusValue) {
			return GLOBAL.taskStatus
				?.filter(i => statusValue == i.value)
				?.reduce((acc, i) => acc = i.label, "")
		},

		classStatus(status) {
			return `status-${status}`
		},


		async sendEdicao(e) {
			NProgress.start();

			const deadline = this.taskdados.deadline
				? { deadline: moment(this.taskdados.deadline, "DD/MM/YYYY").format("YYYY-MM-DD") }
				: {}

			try {
				const request = await axios.patch(`/api/tarefas/${this.data.id}/`, {
					...this.taskdados,
					...deadline,
					cliente_pf: this.data.cliente_pf,
				})
				this.cancelarEdicao()
				this.$emit("enviar", "✅ Tarefa alterada com sucesso")

			} catch (e) {
				console.log(e);
				showToastify("Ocorreu um erro");
			} finally {
				NProgress.done();

			}


		},

		cancelarEdicao() {
			this.taskdados = {}
			this.editado = false
			this.editando = false
			this.$refs.deadline.value = this.data.deadline
			this.$refs.descricao.innerText = this.data.descricao
			this.$refs.deadline.dispatchEvent(new Event("change"))

		},

		handleChange({ target }) {
			const value = target.tagName === "INPUT" ? target.value : target.innerText
			this.taskdados[target.getAttribute("name")] = value.trim()
			this.editado = true
		},


		dataFormated(data) {
			return moment(data, "YYYY/MM/DD").format("DD/MM/YYYY")
		},


		async concluirTask() {
			NProgress.start();

			try {
				const key = this.isFundacao ? "fundacao" : "cliente_pf"
				const value = this.isFundacao ? this.data.fundacao : this.data.cliente_pf
				this.cancelarConclusao()

				const request = await axios.patch(`/api/tarefas/${this.data.id}/`, {
					key: value,
					concluida: true,
					status: "2"
				})

				this.$emit("enviar", " ✅ Tarefa concluida com sucesso")
				this.modalConfirm = false

			} catch (e) {
				GLOBAL.errorDefault(e)
			} finally {
				NProgress.done();

			}

		},

		cancelarConclusao() {
			this.modalConfirm = false

		}


	},

	template: /*html*/`
	<confirm-modal v-if="modalConfirm" @enviar="concluirTask" @cancelar="cancelarConclusao" :text="text" :modal-data="{id: 'check',header: '',}" ></confirm-modal>

	<div v-show="isFundacao ? data.fundacao : data.cliente_pf" class="table-row relative group item-task min-h-[5.5rem] transition-[height]" :class="{'editando' : editando  }"  :data-id="data.id">
	
		<div @click="$emit('open')" class=" w-40 h-40 cursor-pointer" style="grid-area:'icon'">
			<avatar-persona class="w-40 h-40" :type="data.politica"></avatar-persona>
		</div>
	
		<p @click="$emit('open')" class="row-item cursor-pointer" :class="{'only-2-lines' : !editando }" style="grid-area:nome">{{data.nome}}</p>
		<p class="row-item border border-transparent !p-4 rounded-md transition-[height]" ref="descricao" style="grid-area:desc; white-space: break-spaces;"
			@input="handleChange" :contenteditable="editando" name="descricao" :style="{minHeight: editando ? '70px' : null}">{{data.descricao}}</p>

		<p class="row-item" style="grid-area:ultima">-</p>
	
		<p v-show="!editando" class="row-item  border border-transparent !p-4 rounded-md" style="grid-area:deadline">
			{{dataFormated( data.deadline) }}
		</p>
	
		<input v-show="editando" class="row-item  border border-transparent !p-4 rounded-md editavel"
			style="grid-area:deadline" ref="deadline" type="text" @change="handleChange" :value="dataFormated(data.deadline)"
			name="deadline" />
	
		<p class="row-item label" :class="classStatus(data.get_status)" style="grid-area:status">{{ getStatus(data.get_status) }}</p>
	
		<div v-show="!editando" style="grid-area:check" @click="modalConfirm = true">
			<svg class="w-40 h-40 text-neutral-60 hover:text-green-50 transition-colors cursor-pointer" >
				<use xlink:href="#icon_check_task"></use>
			</svg>
		</div>
	
		<button type="button" v-show="!editando" @click="editando = true"
			class="editar absolute  w-32 h-32 opacity-0 group-hover:opacity-100 btn secondary !p-4 right-12 bottom-8">
			<svg class="w-full h-full">
				<use xlink:href="#icon_edit"></use>
			</svg>
		</button>
	
		<div class="flex justify-between col-span-6" v-show="editando">
			<button type="button" class="btn secondary" @click="cancelarEdicao">Cancelar</button>
			<button type="button" class="btn" :class="{'disabled' : !editado }" @click="sendEdicao">Salvar alteracoes</button>
		</div>
	</div>
	`



}