import ModalResumo from '../components/ModalResumo'
import StepTimeLine from '../components/StepTimeLine.js'

export default {
	inject: ['GLOBAL'],
	data() {
		return {
			arrayOfSteps: globalData.steps,
			currentStep: null,
			isCurrentStep: false,
			steps: { step1: { valid: false } },
			isConjuge: false,
			haveModalResumo: true,
			dataForm: {
				nome: '',
				sobrenome: '',
			},
			cepMapFields: {
				state: 'estado',
				city: 'cidade',
				street: 'rua',
				neighborhood: 'bairro',
			},
		}
	},

	components: {
		StepTimeLine,
		ModalResumo,
	},
	methods: {
		/**
		 * Limpa todos os campos que tem o atributo address-fields
		 */
		clearAddressValues() {
			const addressFields = document.querySelectorAll('[address-fields] input')

			addressFields.forEach((field) => (field.value = ''))
		},
		/**
		 * Atualiza os campos referente ao endereco
		 * @param { object } data - Objeto com dados do cep
		 * @param { string } str - string complementar para id
		 */
		fillAddressValues(data, str = '') {
			const arrayOfData = Object.keys(data).map((key) => [key, data[key]])

			arrayOfData.map((arr) => {
				const suffixId = this.cepMapFields[arr[0]]
				const fieldElement = document.querySelector(`[id$=${suffixId}${str}]`)
				if (fieldElement) {
					fieldElement.value = arr[1]
				}
			})

			const pais = document.querySelector(`[id$=pais${str}]`)
			pais.value = 'Brasil'
		},

		/**
		 * Busca o cep usando a api BrasilAPI
		 */
		handleFetchCEP(id, str = '') {
			const cepFieldElement = document.querySelector(id)
			const apiUrl = (cep) =>
				`https://brasilapi.com.br/api/cep/v1/${cep.replace('-', '')}`

			cepFieldElement.addEventListener('input', async ({ target }) => {
				if (target.value.length == 0) this.clearAddressValues()
				if (target.value.length < 9 || this.cepValue == target.value) return

				try {
					const res = await fetch(apiUrl(target.value))
					const data = await res.json()

					if (data.errors) GLOBAL.showToastify('CEP não encontrado')
					else this.fillAddressValues(data, str)
				} catch (err) {
					console.log(err)
				}

				this.cepValue = target.value
			})
		},

		validateIsVazio(form) {
			return form.checkValidity()
		},

		submitGeral() {
			const btn = document.querySelector('[etapa]')
			const form = document.querySelector('form')
			btn?.addEventListener('click', (e) => {
				e.preventDefault()
				if (!this.validateIsVazio(form)) {
					GLOBAL.showToastify(
						' ⚠️ Preencha todos os campos obrigatórios antes de continuar'
					)
					return
				} else {
					form.submit()
				}
			})
		},

		getCurrentStep() {
			const mainElement = document.querySelector("[id^='step']")
			const stepId = mainElement?.getAttribute('id').slice(-1)

			return Number(stepId)
		},
		openModal() {
			modal_padrao.openModal('modal_teste')
		},

		finalizar() {
			const btn = document.querySelector('.finalizar')
			btn.addEventListener('click', () => {
				btn.classList.add('disabled')
				btn.setAttribute('disabled', '')
				this.submitUltimoStep(true)
			})
		},

		/**
		 * @summary Adiciona uma * vermelha na label de uma div com atributo [js-field]
		 * @param {HTMLFormElement} form -
		 */
		addRequiredLabel(form) {
			if (!form instanceof HTMLElement) return
			const fields = form.querySelectorAll('[js-field]')
			if (!fields.length) return

			fields.forEach((f) => {
				if (f.querySelector('[name]')?.required) {
					f.querySelector('label').innerHTML =
						`${f.querySelector('label').textContent}` +
						`<span style="color: rgba(247, 65, 65, 1)"> *</span>`
				}
			})
		},

		stopEnter() {
			document.addEventListener('keydown', function (e) {
				if (e.key === 'Enter') {
					e.preventDefault()
					e.stopPropagation()
					e.stopImmediatePropagation()
				}
			})
		},

		addFlatPickerInputs() {
			const inputs = document.querySelectorAll(
				'[type=date]:not([data-nascimento])'
			)

			inputs.forEach((i) => {
				GLOBAL.initFlatpickr(i, {
					position: 'above',
				})
			})
		},

		// ====================== FUNÇÔES SEPARADAS POR STEPS ======================
		// Step 3
		submitStep3() {
			const btn = document.querySelector('[etapa]')
			const form = document.querySelector('form')
			btn.addEventListener('click', (e) => {
				e.preventDefault()
				if (!this.validateIsVazio(form)) {
					GLOBAL.showToastify(
						' ⚠️ Preencha todos os campos obrigatórios antes de continuar'
					)

					return
				} else if (!this.verificaPorcentagem()) {
					GLOBAL.showToastify(
						' ⚠️ A soma das porcentagens deve ser igual a 100'
					)
				} else {
					form.submit()
				}
			})
		},

		verificaPorcentagem() {
			const container = document.querySelector('[js-field-porc]')
			const selects = container.querySelectorAll('select')

			let total = 0
			selects.forEach((select) => {
				let value = select.value
				if (value) {
					total += Number(value.split('%')[0])
				}
			})
			return total == 100
		},

		// Step 4 e 6
		submitStepPossuiRadio() {
			const btn = document.querySelector('[etapa]')
			const form = document.querySelector('form')
			btn.addEventListener('click', (e) => {
				e.preventDefault()
				if (this.validaStepRadio(form)) {
					GLOBAL.showToastify(
						' ⚠️ Preencha todos os campos obrigatórios antes de continuar'
					)
					return
				} else {
					form.submit()
				}
			})
		},

		/**
		 * Valida etapas que possuem inputs do tipo radio, verificando se a quantidade de inputs com checked corresponde a quantidade de perguntas  obrigatórias
		 * @param { HTMLFormElement} form - formulario a ser validado
		 * @return {Boolean} retorna true ou false
		 */
		validaStepRadio(form) {
			let isVazio = true
			const fields = form.querySelectorAll('[js-field-radio]')

			let check = 0
			fields.forEach((f) => {
				const inputs = f.querySelectorAll('input')

				inputs.forEach((i) => {
					if (i.checked) {
						check++
					}
				})
			})
			if (fields.length === check) {
				isVazio = false
			}
			return isVazio
		},

		// STEP 8

		changeSelectEstadoCivil() {
			const select = document.querySelector('[id$=estado_civil]')
			if (select) {
				select.addEventListener('change', (e) => {
					this.isConjuge = e.target.value == 'Casado(a)'
				})
			}
		},

		submitUltimoStep(finalizar = false) {
			const btn = document.querySelector('[etapa]')
			const checkAutorizar = document.querySelector(
				'[name="8-autoriza_revelar"]'
			)

			const form = document.querySelector('form')
			if (!finalizar) {
				btn.addEventListener('click', (e) => {
					console.log(checkAutorizar.checked, 'check')
					e.preventDefault()
					if (!this.validateIsVazio(form)) {
						GLOBAL.showToastify(
							' ⚠️ Preencha todos os campos obrigatórios antes de continuar'
						)
						return
					} else if (!checkAutorizar.checked) {
						GLOBAL.showToastify(' ⚠️ Para continuar é necessário autorizar ')
						return
					} else {
						this.openModal()
					}
				})
			} else {
				form.submit()
			}
		},
	},

	mounted() {
		const form = document.querySelector('form')

		this.currentStep = this.getCurrentStep()
		this.stopEnter()
		this.addFlatPickerInputs()
		this.addRequiredLabel(form)
		this.finalizar()

		if (this.currentStep === 0) {
			this.handleFetchCEP('#id_0-cep')
			this.submitGeral()
		} else if (this.currentStep === 3) {
			this.submitStep3()
		} else if (this.currentStep == 6 || this.currentStep == 4) {
			this.submitStepPossuiRadio(form)
		} else if (this.currentStep == 8) {
			this.handleFetchCEP('#id_8-cep_comercial', '_comercial')
			this.changeSelectEstadoCivil()
			this.submitUltimoStep()
		} else {
			this.submitGeral()
		}
	},

	delimiters: ['{*', '*}'], //delimitadores pra n conflitar com o django
}
