import tabs from '../../modules/tabs'

const { api } = GLOBAL
export default {
	props: ['open', 'js-tab'],
	emits: ['close', 'update'],
	// Estados reativos (ou iniciais)
	data() {
		return {
			animation: null,
			notifications: [],
			idLastNotification: null,
		}
	},

	// metodo que observa mudanças (onchange)
	watch: {
		open() {
			if (!this.open) {
				console.log('ta fechado 🍃')
				this.notifications = this.notifications.map(
					(i) => (i.visto = true) && i
				)
				const ids = this.notifications.map((i) => i.id)
				axios.post('core/limpar_notificacoes/', ids)
				this.$emit('update', false)
			}
		},

		notifications() {
			if (this.notifications.filter((i) => !i.visto)?.length) {
				this.$emit('update', true)
			} else {
				this.$emit('update', false)
			}
		},
	},

	// Métodos do componente
	methods: {
		handleClose() {
			this.$emit('close', false)
		},

		async getAllNotifications(interval) {
			const { data } = await api.get('/api/notificacao/')

			if (this.notification !== data.results && interval) {
				console.log('Tem, notificacao ai ')
			}

			this.notifications = data.results
		},

		// async getLastNotification() {
		// 	const { data } = await api.get('/api/notificacao/?ultima=1')
		// 	return data[0];
		// },

		async updateStatusViewedNotifications(arr) {
			try {
				const update = await axios.put('/api/notificacao/', arr)
				console.log('atualizado', update)
				this.compareStateWithLastNotification()
			} catch (err) {
				console.log(err)
			}
		},

		onEnter(el, done) {
			const notificationsViewed = this.notifications.map((notification) => ({
				...notification,
				visto: true,
			}))
			this.updateStatusViewedNotifications(notificationsViewed)
			//Executando animação no modal
			this.animation = gsap
				.timeline()
				.set(el, { opacity: 0, translateY: -25 })
				.set(document.querySelector('.background-deep'), { opacity: 0 })
				.to(el, { opacity: 1, translateY: 0, duration: 0.35 }, 'rodaJunto')
				.to(
					document.querySelector('.background-deep'),
					{ opacity: 1 },
					'rodaJunto'
				)
				.add(() => tabs())

			done()
		},

		leave(el, done) {
			this.animation
				.timeScale(2)
				.reverse()
				.then((result) => done())
		},

		async compareStateWithLastNotification() {
			const lastNotification = await this.getLastNotification()

			if (lastNotification.id !== this.$data.idLastNotification.id) {
				document.body.style.setProperty('--notificacao', '#C11F39')
				this.getAllNotifications()
			}
		},
	},

	// utils do componente
	computed: {
		get() {},
	},

	// Executa assim que o componenete é criado no dom
	// Executa um cado antes do mounted
	created() {},

	// Executa assim que o componente é montado
	async mounted() {
		await this.getAllNotifications()

		interval = setInterval(() => {
			this.getAllNotifications(true)
		}, 15000)

		// this.idLastNotification = await this.getLastNotification();

		// this.compareStateWithLastNotification();

		// const callNotification = () => {
		// 	setTimeout(async () => {
		// 		await this.compareStateWithLastNotification();
		// 		callNotification();
		// 	}, 10000);
		// }
		// callNotification();

		// debounce que vai bater nessa rota de notificacao
		// com um filtro
		// dentro da resposta vc vai pegar e salvar o dado

		// no proximo request vc vai verificar se a dado é igual a dado anterior

		// se nao for vc roda get all notifications
		// e roda tambem uma funcao pra deixar o icone vermelho

		// quando abrir a notificacao, vc vai setar tudo como visto
		// enviar em um patch

		// observar o array pra ver se tem algum nao lido e deixar vermelho
	},

	//Executa um pouco antes de desmontar o componente
	beforeUnmounted() {},
	// Executa ao desmontar o componente
	unmounted() {},

	template: /* html */ `
    <Transition @enter="onEnter" @leave="leave">
        <section v-if="open" class="w-[39.75rem]  h-[calc(100vh-4rem-18px)] rounded-md absolute overflow-hidden z-[9999999999999999999999] top-[4rem] right-64">
            <header
                class="flex items-center justify-between px-24 pt-24 pb-16 bg-primary-pure relative z-[9999]"
            >
            <h2 class="text-lg font-medium text-white">Notificações</h2>
                <button
                    type="button"
                    class="w-32 h-32 transition-transform rounded-full cursor-pointer hover:scale-125 bg-white/10"
                    data-xclose="modal_padrao"
                    @click="handleClose"
                >
                    <svg class="w-32 h-32">
                        <use xlink:href="#icon_close"></use>
                    </svg>
                </button>
            </header>
            <div class="p-24 bg-white h-full relative z-[9999]">
                <div class="flex flex-col h-full tabs" js-tab>
                    <ul class="flex border-b tabs-wrapper border-b-neutral-30">
                        <li class="relative gap-8 tab-item !flex-[0] whitespace-nowrap tab-item-primary active" data-tab="tab-modal-1">
                            Todas <span class="block px-8 ml-6 text-sm font-medium rounded-full badge-container bg-neutral-30 text-neutral-70">{{notifications.length}}</span>
                        </li>
                        <!-- <li class="relative gap-8 tab-item !flex-[0]  tab-item-primary" data-tab="tab-modal-2">
                            Atendimentos
                        </li>
                        <li class="relative gap-8 tab-item !flex-[0]  tab-item-primary" data-tab="tab-modal-3" >
                            Tarefas
                        </li> -->
                    </ul>

                    <div class="flex flex-col h-full overflow-auto tabs-content">
                        <div class="tab-collapse active" data-tab="tab-modal-1">
                            <div class="relative flex-1 tab-container pb-80" >
                                <div class="flex flex-col gap-8 mt-8" :value="notification.id" v-for="notification in notifications"  :class="{'nao-visto': !notification.visto}">
                                    <div class="relative flex items-center gap-8 p-16 border rounded-md border-neutral-30"  >
                                        <span class="grid w-40 h-40 text-white rounded-full bg-primary-10 place-items-center shrink-0 !self-baseline">
										<svg class="h-24 w-24 !text-primary-pure"  >
											<use xlink:href="#icon_notification_person"></use>
										</svg>
                                        </span>
                                        <div>
											
                                            <p class="text-sm font-medium text-neutral-100">{{notification.titulo}}</p>
                                            <span class="text-sm font-medium text-neutral-70">{{notification.descricao}}</span>
                                        </div>
																			<!-- <label class="absolute inline-flex items-center gap-8 font-medium cursor-pointer radio-container round size-20 right-16 top-16">
																					<div class="check-label">
																							<input type="checkbox" name="status" class="sr-only checkInput" />
																							<span class="radio-circle">
																									<i class="radio-circle-inner"></i>
																							</span>
																					</div>
																			</label> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </Transition>

		<Teleport to="body" v-if="open" >
				<div class="background-deep fixed left-0 top-0 bg-[rgba(0,0,0,0.25)] w-[100vw] h-[100vh] z-[999]"
						@click="handleClose"
				></div>
		</Teleport>

    `,
}
