export default {
	props: ["text", "data", "modalData"],

	methods: {
		send() {
			this.$emit("enviar", this.data)
		},
		cancel() {
			this.$emit("cancelar", this.data)
		},
		handleClick({ target }) {
			console.log("clicou fora")
			if (target !== modal || !target.closest("modal-sucesso-1-wrapper")) {
				this.cancel()
			}
		},

		onBeforeEnter(el) {
			gsap.set(el, {
				y: 30,
				scale: 1,
			})
			gsap.set(el.querySelector("svg"), {
				scale: 0
			})
		},


		onEnter(el, done) {
			gsap
				.timeline({ ease: "power1", delay: 0 })
				.to(el, {
					y: 0,
					duration: 0.2

				})

			gsap.to(el.querySelector("svg"), {
				scale: 1,
				duration: 0.2,
				transformOrigin: "bottom"
			})
			done()
		},

	},

	mounted() {
		gsap.to(this.$refs.modal, { y: 0 })
	},


	template: `
			<Transition 
			@before-enter="onBeforeEnter" 
			@enter="onEnter" 
			>
				<div class="modal-sucesso-1">
					<header v-if="modalData.header" class="rounded-t-lg bg-primary-pure p-20 h-[60px] text-white flex items-center" >
						<p>{{modalData.header}}</p>
					</header>
					<div :class="{'!rounded-t-none': modalData.header}"
						class="modal-sucesso-1-wrapper bg-white border border-neutral-30 flex flex-col w-full  max-w-[400px] overflow-hidden   h-max mx-auto py-16 px-24 gap-24 shadow-lg rounded-lg"
						ref="modal" style="transform:translatey(30px);">
						<div class="flex flex-col gap-8 mx-auto">
							<div v-if="modalData">
								<div v-if="modalData.class == 'remove'">
			
									<svg class="w-48 h-48 mx-auto my-16" xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 496.158 496.158"
										style="enable-background:new 0 0 496.158 496.158;" xml:space="preserve">
										<path d="M0,248.085C0,111.063,111.069,0.003,248.075,0.003c137.013,0,248.083,111.061,248.083,248.082 c0,137.002-111.07,248.07-248.083,248.07C111.069,496.155,0,385.087,0,248.085z"   style="fill: rgb(var(--primary-pure));"/>
										<path style="fill:#FFFFFF;" d="M383.546,206.286H112.612c-3.976,0-7.199,3.225-7.199,7.2v69.187c0,3.976,3.224,7.199,7.199,7.199
							h270.934c3.976,0,7.199-3.224,7.199-7.199v-69.187C390.745,209.511,387.521,206.286,383.546,206.286z" />
			
									</svg>
			
			
								</div>
								<div v-if="modalData.class === 'fire'">
									<svg class="w-48 h-48 mx-auto my-16">
										<use xlink:href="#icon_fire_filled"></use>
									</svg>
			
								</div>
			
							</div>
							<p class="text-center text-neutral-80 font-semibold py-16" v-html="text"></p>
							<div class="flex justify-between col-span-6 gap-32">
								<button class="btn secondary" @click="cancel">Cancelar</button>
								<button class="btn" @click="send">{{modalData?.botao ? modalData.botao  : 'Concluir'}}</button>
							</div>
						</div>
					</div>
			
				</div>
			</Transition>
	
	`
}