export default {
	props: [
		'clienteAtivo',
		'setPolitica',
		'patrimonio',
		'isFundacao',
		'consultorias',
		'dataAtualCarteira',
		'options'
	],
	emits: ['openModal', 'openModalRemoveQuente', 'change-select'],

	data() {
		return {
			labels: '',
		}
	},

	methods: {
		openModal() {
			this.$emit('openModal')
		},
		openModalRemoveQuente() {
			this.$emit('openModalRemoveQuente', this.clienteAtivo)
		},
		dateFormated(date) {
			return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
		},
		showLabels() {
		
			if (
				clienteAtivo.consultoria ||
				clienteAtivo.gestao ||
				clienteAtivo.distribuicao
			) {
			
				//console.log(clienteAtivo)
			}
		},
	},

	watch: {
		clienteAtivo: {
			handler(newValue, oldValue) {
			//	console.log('CLIENTE ATIVASSSOO', newValue)
				const { distribuicao, gestao, consultoria } = newValue

				const obj = {
					distribuicao,
					gestao,
					consultoria,
				}

				let objReferencia = {
					gestao: 'Gestão',
					distribuicao: 'Distribuição',
					consultoria: 'Consultoria',
				}

				let arr = Object.entries(obj)
					.filter(([key, val]) => val)
					.map(([key, val]) => objReferencia[key])
				if (!arr.length) return
				let result = arr.join(', ')
				if (arr.length > 2) {
					let position = String(result).lastIndexOf(',')
					result =
						result.substring(0, position) +
						' e' +
						result.substring(position + 1)
				}
				this.labels = result
			},
			deep: true,
		},
	},

	template: /* html */ `
			<div>
				<div class="flex flex-wrap items-center gap-8 mb-16 text-xs text-neutral-100" v-if="!isFundacao">
						<span class="badge badge-mini text-xs">
							{{ setPolitica(clienteAtivo.politica) }}
						</span>

						<span v-show="clienteAtivo.estado_sigla" class="flex gap-4 items-center">
							<svg class="h-20 w-20">
								<use xlink:href="#icon_mapa"></use>
							</svg>
							{{ clienteAtivo.estado_sigla }}
						</span>

						<div v-if="clienteAtivo.consultoria_id && isFundacao" class="inline-flex items-center gap-4 text-xs text-neutral-100">
							
									<svg class="w-24 h-24" style="--icon-color: #C11F39">
										<use xlink:href="#icon_pessoa_fisica"></use>
									</svg>
								
							<p>{{ consultorias.find(consultoria => consultoria.id === clienteAtivo.consultoria_id).nome }}</p>
						</div>

						<div class="inline-flex items-center gap-4 text-xs text-neutral-100" v-show="!isFundacao">
							<p>PLE:</p>
							<span class="badge badge-mini">
								R$  {{ !patrimonio || patrimonio == "0,00"  ?  "-" : patrimonio }}
							</span>
						</div>

						<!-- <button v-show="!clienteAtivo.atendimento_quente" type="button" class="py-4 px-8 mt-4 text-neutral-80 font-medium inline-flex items-center gap-4 transition-colors w-max rounded-md border border-neutral-50 hover:text-primary-pure hover:bg-primary-pure/10 hover:border-primary-pure group shrink-0"  @click="openModal">
							<svg class=" w-14 h-18 group-hover:hidden"><use xlink:href="#icon_fire_outline"></use></svg>
							<svg class=" w-14 h-18  hidden group-hover:!block "><use xlink:href="#icon_fire_filled"></use></svg>
							Atendimento quente
						</button>

						<button v-show="clienteAtivo.atendimento_quente" type="button" class="btn-remove-quente py-4 px-8 font-medium inline-flex items-center gap-4 transition-colors w-max rounded-md border  text-primary-pure bg-primary-pure/10 border-primary-pure shrink-0"  @click="openModalRemoveQuente">
							<svg class=" w-14 h-18 "><use xlink:href="#icon_fire_filled"></use></svg>
							Atendimento quente
						</button> -->

						<div v-if="clienteAtivo.data_nascimento" class="inline-flex items-center gap-4 text-xs text-neutral-100"  v-show="!isFundacao">
							
								<svg class="w-20 h-20" style="--icon-color: #C11F39">
									<use xlink:href="#icon_agenda"></use>
								</svg>
							
							<p>{{clienteAtivo.idade}} Anos - {{ dateFormated(clienteAtivo.data_nascimento) }}</p>
						</div>

						<div v-if="clienteAtivo.gestao || clienteAtivo.consultoria || clienteAtivo.distribuicao" class="inline-flex items-center gap-4 text-xs text-neutral-100"  v-show="!isFundacao">
						
							<svg class="w-20 h-20" style="--icon-color: #C11F39">
								<use xlink:href="#icon_consultoria"></use>
							</svg>
							<p>{{labels}}</p>
						</div>
						
						
						<div v-if="clienteAtivo.taxa && (clienteAtivo.gestao || clienteAtivo.consultoria || clienteAtivo.distribuicao)" class="inline-flex items-center gap-4 text-xs text-neutral-100"  v-show="!isFundacao">
							<svg class="w-20 h-20" style="--icon-color: #C11F39">
								<use xlink:href="#icon_taxa"></use>
							</svg>
							<p>{{clienteAtivo.taxa}}%</p>
						</div>
						
						<div  class="select-container select-status !h-32 items-center gap-4 !text-xs text-neutral-100"  v-show="!isFundacao">
								<select  @change="(e) => $emit('change-select', e)" class="!text-xs" js-choices  name="status" id="status-modal-select" js-choices-props='{"searchEnabled": true, "searchChoices": true}'>
										<option class="!text-xs" value="" selected>Selecione um status</option>
										<option class="!text-xs" :key="opt.value" :value="opt.value" v-for="opt in options">{{opt.label}}</option>
								</select>
						</div>
				</div>

				<div class="flex items-center justify-between gap-8">	
					<p class="flex items-center gap-6 text-sm neutral-100">
						<svg class="h-20 w-20 shrink-0" v-if="!isFundacao && clienteAtivo.sigla ">
							<use xlink:href="#icon_person_2"></use>
						</svg>
						
						<svg v-else-if="isFundacao && clienteAtivo.sigla" class="w-[20px] h-[20px] shrink-0" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6.66602 17.5028V3.33026C6.66602 2.86983 7.03927 2.49658 7.4997 2.49658H12.5018C12.9622 2.49658 13.3355 2.86983 13.3355 3.33026V17.5028" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M17.5034 17.5031V6.59269C17.5034 6.17232 17.1626 5.83154 16.7422 5.83154H13.335" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M6.66547 5.83154H3.25822C2.83785 5.83154 2.49707 6.17232 2.49707 6.59269V17.5031" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M13.335 8.33237H15.4192" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M13.335 10.8333H15.4192" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M13.335 13.3343H15.4192" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M4.58105 8.33237H6.66526" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M4.58105 10.8333H6.66526" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M4.58105 13.3343H6.66526" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M8.75 10.8333H11.251" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M8.75 13.3343H11.251" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M8.75 8.33237H11.251" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M8.75 5.83139H11.251" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M9.99985 15.8354V17.5028" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M18.3367 17.5028H1.66309" stroke="#003769" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
						{{ clienteAtivo.sigla }}
					</p>
				</div>
	`,
}
