import { useApi } from '../composables/useApi'

export function clientAssetsService() {
	const api = useApi()

	async function getDadosTempoVencimento(clienteId) {
		return api.query(
			`${window.location.origin}/atendimento/grafico_tempo_vencimento/${clienteId}`
		)
	}

	async function getDetalhesVolatilidade(clienteId, periodo,data_inicio,data_fim) {
		return api.query(
			`${window.location.origin}/api/tabela_clientepf/detalhes_volatilidade/?cliente=${clienteId}&periodo=${periodo}&data_inicio=${data_inicio}&data_fim=${data_fim}`
		)
	}

	async function getDetalhesRetorno(clienteId, quatidade_meses) {
			return api.query(
				`${window.location.origin}/api/tabela_clientepf/detalhes_retorno/?cliente=${clienteId}&quantidade_meses=${quatidade_meses}`
			)
		}
	return {
		getDadosTempoVencimento,
		getDetalhesVolatilidade, 
		getDetalhesRetorno
	}
}
