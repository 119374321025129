export default {
	props: ['link', 'stepId', 'currentStep', 'title'],

	template: html`
		<div
			class="relative step-line"
			:class="{'active': currentStep > stepId,  'metade': stepId == 7, 'isLast': currentStep > 8 && stepId == 9} ">
			<svg
				v-if="currentStep == stepId"
				width="13"
				height="12"
				viewBox="0 0 13 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<rect
					x="0.833008"
					y="0.5"
					width="11"
					height="11"
					rx="5.5"
					fill="#FFF" />
				<circle cx="6.33281" cy="5.99981" r="4.2" fill="#C11F39" />
				<rect
					x="0.833008"
					y="0.5"
					width="11"
					height="11"
					rx="5.5"
					stroke="#C11F39" />
			</svg>
			<svg
				v-else-if="currentStep > stepId"
				width="12"
				height="12"
				viewBox="0 0 12 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<rect width="12" height="12" rx="6" fill="#C11F39" />
			</svg>
			<svg
				v-else
				width="12"
				height="12"
				viewBox="0 0 12 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<rect width="12" height="12" rx="6" fill="white" />
				<rect
					x="0.5"
					y="0.5"
					width="11"
					height="11"
					rx="5.5"
					stroke="#09121C"
					stroke-opacity="0.1" />
			</svg>

			<a
				v-if="currentStep == stepId"
				:href="link"
				class="paragraph-2 text-neutral-100  w-full min-w-[200px] text-sm font-normal absolute"
				:class="{'font-medium': currentStep == stepId, 'pointer-events-none': currentStep <= stepId}">
				{{title}}
			</a>
			<a
				v-else
				:href="link"
				class="paragraph-2 text-neutral-100 mt-8 w-full text-sm font-normal"
				:class="{'font-medium': currentStep == stepId, 'pointer-events-none': currentStep <= stepId}">
			</a>
		</div>
	`,
}
