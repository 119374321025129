export const MONTHS_LIST = [
	{ id: 1, nome: 'Janeiro' },
	{ id: 2, nome: 'Fevereiro' },
	{ id: 3, nome: 'Março' },
	{ id: 4, nome: 'Abril' },
	{ id: 5, nome: 'Maio' },
	{ id: 6, nome: 'Junho' },
	{ id: 7, nome: 'Julho' },
	{ id: 8, nome: 'Agosto' },
	{ id: 9, nome: 'Setembro' },
	{ id: 10, nome: 'Outubro' },
	{ id: 11, nome: 'Novembro' },
	{ id: 12, nome: 'Dezembro' },
]

export const CONFIG_GRID_DEFAULT = {
	search: false,
	resizable: true,
	data: [],
	autoWidth: true,
	width: '100%',
	sort: false,
	style: {
		container: { border: 'none' },
		table: { border: 'none' },
		tr: { border: 'none' },
		th: { 'border-bottom': '1px solid #e9ecef' },
		td: {
			'border-bottom': '1px solid #e9ecef',
			cursor: 'pointer',
			color: '#495057',
			'font-family': 'Roboto Flex',
			'font-size': '14px',
			'font-weight': 500,
			'line-height': '150%',
			'letter-spacing': '-0.28px',
		},
		footer: { border: 'none' },
	},
	language: {
		search: { placeholder: 'Busque aqui...' },
		pagination: { previous: ' Anterior', next: 'Próximo ' },
	},
}
