import { html } from 'gridjs'

export default {
	template: html`
		<section
			class="modal--content rounded-md flex-1 !h-max overflow-[initial]"
			data-id="emissor_fgc_ativos">
			<h1>Hello World</h1>
		</section>
	`,
}
