import { SETOR_CHART_ID } from '../constants/setor_charts'
import { useCharts } from './useCharts'

const { createChart } = useCharts()

export function useSetorChart() {
	function createSetorChart(dados) {
		const dadosValidos = Object.entries(dados).filter(dado => dado[1] !== 0)
		const labels = dadosValidos.map(dado => dado[0]);
		const series = dadosValidos.map(dado => dado[1].quantidade);
		const percentual = dadosValidos.map(dado => dado[1].percentual);
		console.log({dados, labels, series, dadosValidos})
		
		const colors = GLOBAL.defineColors(labels)
		const options = {
			series,
			chart: {
				width: '100%',
				height: 340,
				id: 'chart-setor',
				type: 'pie',
			},
			noData: {
				text: 'Sem valores'
			},
			labels,
			legend: {
				position: 'bottom',
				onItemClick: {
					toggleDataSeries: false 
				},
				 onItemHover: {
          highlightDataSeries: false
      },
				
			},
			colors,
			dataLabels: {
				enabled: false
			},
			tooltip: {
				enabled: true,
				shared: true,
				intersect: false,
				custom: (seriesName) => {
					const percentualValue = percentual[seriesName.seriesIndex]
					const currentIndex = labels[seriesName.seriesIndex]
					const bgColor = colors[seriesName.seriesIndex]
					return `<div class="arrow_box p-8 !bg-[${bgColor}]" style="background: ${bgColor} !important">
									<p class="font-semibold text-sm text-white">Setor</p>
									<p class="font-medium text-sm text-white">${currentIndex} : ${percentualValue}%</p>
									
								</div>`
				},
					
			},
			

		};



		createChart(SETOR_CHART_ID, options)

	}

	return {
		createSetorChart,
	}
}

