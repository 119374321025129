import { useApi } from '../composables/useApi'

export function reuniaoService() {
	const api = useApi()

	async function fetchMeetingData(query) {
		return api.query(
			`${window.origin}/atendimento/reunioes_realizadas_sai/${query}`
		)
	}
	return {
		fetchMeetingData,
	}
}
