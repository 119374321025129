const { ref } = Vue
import { className, Grid, h, html as htmlGrid } from 'gridjs'
import { capitalize } from 'lodash'
import ConfirmModal from '../components/ConfirmModal.js'
import ModalAprovacao from '../components/ModalAprovacao.js'

export default {
	emits: [],

	props: {},
	components: {
		ModalAprovacao,
		ConfirmModal,
	},

	data() {
		return {
			tableSolicitacoesAprovacoes: null,
			tableHistoricoAprovacoes: null,
			solicitacoes: [],
			modalConfirm: {},
			solicitacao: {},
			countAguardando: 0,
			countAprovado: 0,
		 	tipos_atendimentos: {
				edita_atendimento: 'Edição de atendimento',
				edita_contato: 'Edição de ponto de contato',
				deleta_atendimento: 'Exclusão de atendimento'
			}
		}


	},
	methods: {

		openModalHistorico(row) {
			const tipoSolicitacao = row?.cells[2].data
			const index = row?.cells[4].data
			const itemClicked = this.solicitacoes?.find(solicitacao => {
				return solicitacao.id_index === index
			})
			const dataSolicitacao = new Date(itemClicked.data_solicitacao)
			this.solicitacao = {
				tipo: tipoSolicitacao,
				header: this.tipos_atendimentos[tipoSolicitacao],
				...itemClicked,
				data_solicitacao: GLOBAL.formatDateObject(dataSolicitacao)
			} 

			modal_padrao_vue.openModal(`modal_aprovacao`)
		},

		async postSolicitacao(url) {
			try {
				NProgress.start();
				const { data } = await axios.get(url);
			} catch (e) {
				errorDefault(e);
			} finally {
				NProgress.done();
			}
		},

		openModalSolicitacao(row, acao) {
			const isAprovar = acao === 'aprovar';
			this.modalConfirm = {
				open: true,
				rowClick: row,
				text: `Deseja ${isAprovar ? 'aprovar' : 'recusar'} esta solicitação?`,
				id: acao,
				botao: 'Confirmar',
				header: `${isAprovar ? 'Aprovar' : 'Recusar'} Solicitação`,
			};
		},

		setUrlSolicitacao(row, acao) {
			let url = `${window.location.origin}/`;
			const isAtendimento = row.cells[1].data;
			const id = row.cells[0].data;
			const tipoSolicitacao = row.cells[2].data;
			const acaoUrl = acao === 'aprovar' ? 'aprovado' : 'recusado';

			if (isAtendimento) {
				const isDeletaAtendimento = tipoSolicitacao === 'deleta_atendimento';
				const isEditaAtendimento = tipoSolicitacao === 'edita_atendimento';
				if (isDeletaAtendimento) {
					url += `atendimento/${acaoUrl}_delete/${id}`;
				} else if (isEditaAtendimento) {
					url += `atendimento/${acaoUrl}/${id}`;
				}
			} else {
				url += `clientes/${acaoUrl}_editar_ponto_contato/${id}/`;
			}
			return url;
		},

		async processarSolicitacao(row, acao) {
			try {
				const url = this.setUrlSolicitacao(row, acao);
				await this.postSolicitacao(url);
				this.modalConfirm = {};
				this.tableSolicitacoesAprovacoes.forceRender();
				this.tableHistoricoAprovacoes.forceRender();
			} catch (error) {
				console.log(error);
			}
		},

		async confirmAction() {
			await this.processarSolicitacao(this.modalConfirm.rowClick, this.modalConfirm.id);
		},




		formatOfficersNames(officers) {
			return officers?.map(({ nome }) =>
				nome
					.split('.')
					.map((word) => capitalize(word))
					.join(' ')
			)
				.join(', ')
		},

		mapSolicitacao(item, index, exclude) {
			const isDateAlteracao = (item.status_exclusao !== 'Aguardando' || item.status_edicao !== 'Aguardando') && item.data_alteracao
			const isAtendimento = item.tipo_solicitacao === 'edita_atendimento' || item.tipo_solicitacao === 'deleta_atendimento';
			
			const objTable = {
				id: item.atendimento || item.contato,
				'is-atendimento': isAtendimento,
				'tipo-solicitacao': item.tipo_solicitacao,
				'conteudo-editado': item.conteudo_editado,
				'index': index,
				cliente: item.cliente_pf,
				officer: item.usuario_solicitante,
				tipo_atendimento: this.tipos_atendimentos[item.tipo_solicitacao],
				data_solicitacao: isDateAlteracao ? item.data_alteracao : item.data_solicitacao,
				usuario_aprovador: item.usuario_aprovador,
				status_acao: item.status_exclusao || item.status_edicao
			}
			
			if (!exclude) {
				delete	objTable.usuario_aprovador
			}
			return objTable
		},

		filterSolicitacoes(solicitacoes, filterStatus, exclude) {

			return solicitacoes.filter(solicitacao =>
				exclude ? solicitacao.status_acao !== filterStatus : solicitacao.status_acao === filterStatus
			);
		},

		mapAndFilterSolicitations(solicitacoes, filterStatus = 'Aguardando', exclude = true) {
			this.solicitacoes = solicitacoes?.map((solicitacao, index) =>( {id_index:index, ...solicitacao,}))
			const _arrData = solicitacoes?.map((item, index) => this.mapSolicitacao(item, index, exclude));
			const solicitacoesFiltradas = this.filterSolicitacoes(_arrData, filterStatus, exclude);
			
			if (exclude) {
				this.countAprovado = solicitacoesFiltradas.length
			} else {
				this.countAguardando = solicitacoesFiltradas.length
			}
			return solicitacoesFiltradas.map(item => Object.values(item));
		},

		iniciarTabelaGenerica(tableSelector, columns, url, customThenCallback, customTotalCallback) {
			const table = document.querySelector(tableSelector);

			const gridInstance = new Grid({
				search: false,
				resizable: true,
				columns: columns,
				data: [],
				autoWidth: true,
				width: '100%',
				sort: false,
				server: {
					url: url,
					then: (data) => customThenCallback(data),
					total: (data) => customTotalCallback(data).length,
				},
				pagination: {
					enabled: false,
					limit: 10,
					summary: false,
					server: {
						url: (urlApi, page, limit) => `${urlApi}&page=${++page}&paginate_by=${limit}`,
					},
				},
				style: {
					container: { border: 'none' },
					table: { border: 'none' },
					tr: { border: 'none' },
					th: { 'border-bottom': '1px solid #e9ecef' },
					td: {
						'border-bottom': '1px solid #e9ecef',
						cursor: 'pointer',
						color: '#495057',
						'font-family': "Roboto Flex",
						'font-size': '14px',
						'font-weight': 500,
						'line-height': '150%',
						'letter-spacing': '-0.28px',
						
					},
					footer: { border: 'none' },
				},
				language: {
					search: { placeholder: 'Busque aqui...' },
					pagination: { previous: ' Anterior', next: 'Próximo ' },
				},
			});

			gridInstance.render(table);
			return gridInstance;
		},

		iniciartableSolicitacoesAprovacoes() {
			const columns = [
				
				{ name: 'ID', hidden: false, width: '100px' },
				{ name: 'is-atendimento', hidden: true },
				{ name: 'tipo-solicitacao', hidden: true },
				{ name: 'conteudo-editado', hidden: true },
				{ name: 'index', hidden: true },
				{ name: 'Cliente_pf', hidden: false },
				{
					name: 'Officer',
					formatter: (cell) => this.formatOfficersNames([{ nome: cell }])
				},
				{
					name: htmlGrid('<div style="white-space: normal;">Tipo de solicitação</div>'),
				},
				{
					name: htmlGrid('<div style="white-space: normal;">Data de Solicitação</div>'),
					formatter: (cell) => GLOBAL.formatDateObject(new Date(cell)),
				},
				{ name: 'Status' },
				{
					name: '',
					width: '120px',
					formatter: (cell, row) => this.renderAcoes(row),
				}
			];

			this.tableSolicitacoesAprovacoes = this.iniciarTabelaGenerica(
				'#table_solicitacoes_aprovacoes',
				columns,
				`${window.origin}/atendimento/solicitacoes/?`,
				(data) => this.mapAndFilterSolicitations(data, 'Aguardando', false),
				(data) => this.mapAndFilterSolicitations(data, 'Aguardando', false)
			);
		},

		iniciartableSHistoricoAprovacoes() {
			const columns = [
				
				{ name: 'ID', hidden: false, width: '100px' },
				{ name: 'is-atendimento', hidden: true },
				{ name: 'tipo-solicitacao', hidden: true },
				{ name: 'conteudo-editado', hidden: true },
				{ name: 'index', hidden: true },
				
				{ name: 'Cliente_pf', hidden: false },
				{
					name: 'Officer',
					formatter: (cell) => this.formatOfficersNames([{ nome: cell }])
				},
				{
					name: htmlGrid('<div style="white-space: normal;">Tipo de solicitação</div>'),
				},
				{
					name: htmlGrid('<div style="white-space: normal;">Data de Alteração</div>'),
					formatter: (cell) => GLOBAL.formatDateObject(new Date(cell)),
				},
				{ name: 'Aprovador' },
				{ name: 'Status' },
				{
					name: '',
					width: '80px',
					formatter: (cell, row) => this.renderHistoricoAcoes(row),
				}
			];

			this.tableHistoricoAprovacoes = this.iniciarTabelaGenerica(
				'#table_historico_aprovacoes',
				columns,
				`${window.origin}/atendimento/solicitacoes/?`,
				(data) => this.mapAndFilterSolicitations(data),
				(data) => this.mapAndFilterSolicitations(data)
			);
		},

		renderAcoes(row) {
			return h('div', {
				className: 'flex gap-2 justify-between',
			}, [
				h('ion-icon', {
					name: 'checkmark-outline',
					className: 'success',
					onClick: () => this.openModalSolicitacao(row, 'aprovar'),
				}),
				h('ion-icon', {
					name: 'close-outline',
					className: 'error',
					onClick: () => this.openModalSolicitacao(row, 'recusar'),
				}),
			]);
		},

		renderHistoricoAcoes(row) {
			return h('ion-icon', {
				name: 'search-outline',
				onClick: () => this.openModalHistorico(row),
			});
		},

	},
	async mounted() {
		this.iniciartableSolicitacoesAprovacoes()
		this.iniciartableSHistoricoAprovacoes()
	
	},
delimiters: ['{*', '*}'],
}
