import { CARTEIRA_MARGINS_CHART_OPTIONS } from '../constants/carteira_margins'
import { LIQUIDEZ_MARGINS_CHART_ID } from '../constants/liquidez_charts'
import { useCharts } from './useCharts'
import { useConcentracao } from './useConcentracao'

const { buildMarginsLines, serializeLimites } = useConcentracao()
const { createChart, setColorsInFunctionOfAHexadecimalColor } = useCharts()

export function useLiquidezMarginsChart() {
	function createLiquidezMarginsChart(limitesLiquidez, dados) {
		const { limites, valores_liquidez } = limitesLiquidez
		const seriesData = valores_liquidez.map(({ carteira }) =>
			parseFloat(carteira).toFixed(2)
		)
		const categories = valores_liquidez.map(({ periodo }) => periodo)
		const margins = limites.map(({ limite_inferior, limite_superior }) =>
			serializeLimites(limite_inferior, limite_superior)
		)
		const options = {
			...CARTEIRA_MARGINS_CHART_OPTIONS,
			
			series: [
				{
					name: 'Carteira',
					type: 'bar',
					data: seriesData,
				},
			],
			colors: setColorsInFunctionOfAHexadecimalColor('#7B0100', seriesData, 20),
			xaxis: {
				type: 'category',
				categories: categories,
				max: 100,
				stepSize: 10,
				labels: {
					formatter: (value) => {
						return `${value}%`
					},
				},
			},
			title: {
				text: undefined,
			},
			legend: {
				show: false,
			},
			tooltip: {
				enabled: true,
				shared: false,
				followCursor: true,
				x: {
					show: false,
				},
				custom: handleCustomTooltip(margins, categories, dados),
			},
		}

		createChart(LIQUIDEZ_MARGINS_CHART_ID, options)
		buildMarginsLines(LIQUIDEZ_MARGINS_CHART_ID, margins)
		window.addEventListener('resize', () =>
			setTimeout(
				() => buildMarginsLines(LIQUIDEZ_MARGINS_CHART_ID, margins),
				500
			)
		)
	}
	function keyVencimento(key) {
			let keyVencimento = ''
			switch (key) {
				case 'Até 5 dias':
					keyVencimento = 'vencimento_5_dias'
					return keyVencimento
					case 'De 6 a 35 dias':
					keyVencimento = 'vencimento_6_a_35_dias'
					return keyVencimento
					
				case 'De 36 a 65 dias':
					keyVencimento = 'vencimento_36_a_65_dias'
					return keyVencimento
					case 'Acima de 65 dias':
					keyVencimento = 'vencimento_65_dias'
					return keyVencimento
				default:
					return keyVencimento
				
				
					
			}
		}

	function handleCustomTooltip(margins, categories, dados) {
		return ({ series, seriesIndex, dataPointIndex }) => {
			const margin = margins[dataPointIndex]
			const periodo = categories[dataPointIndex]
			const keyPeriodo = keyVencimento(periodo)
			
			const qtdAtivos = dados[keyPeriodo] ? Object.values(dados[keyPeriodo])?.length : 0
			
			return `
			<div class="arrow_box p-8 bg-white"
			>
				<p class="font-medium text-sm text-neutral-100">${periodo}</p>
				<p class="text-neutral-70 font-normal text-xs">Carteira: ${parseFloat(
					series[seriesIndex][dataPointIndex]
			).toFixed(2)}%</p>
				<p class="text-neutral-70 font-normal text-xs">QTD. Ativos: ${qtdAtivos}</p>
				${
					margin
						? `<p class="text-neutral-70 font-normal text-xs">Margem inferior: ${margin[0]}%</p>
				<p class="text-neutral-70 font-normal text-xs">Margem superior: ${margin[1]}%</p>`
						: ``
				}
			</div>
		`
		}
	}

	return {
		createLiquidezMarginsChart,
	}
}
