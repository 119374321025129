const { ref } = Vue
import { className, Grid, h, html as htmlGrid } from 'gridjs'
import SelectMultiple from '../components/SelectMultiple'
import ModalResumo from '../components/ModalResumo.js'
import { capitalize } from 'lodash'
import { MONTHS_LIST } from '../constants/reuniao.js'

export default {
	emits: [],

	props: {},
	data() {
		return {
			tableReunioesRealizadas: null,
			reunioesRealizadas: [],
			haveModalResumo: true,
			columns: [
				{ id: 'id', name: 'ID', hidden: true },
				{ id: 'politica', name: 'Politica', hidden: true },
				{ id: 'nome', name: 'Cliente PF', hidden: false, width: '250px' },
				{
					id: 'usuario_responsavel_atendimento',
					name: 'Officer',
					hidden: false,
					width: '150px',
					formatter: (cell, row) => {
						return htmlGrid(`<div style="display:flex; flex-direction:column; gap:2px; "'>
							${cell
								?.map((item) => {
									const nameFormated = item
										.split('.')
										.map((word) => capitalize(word))
										.join(' ')
									return `<span>${nameFormated || ''}</span>`
								})
								.join(' ')}
						</div>`)
					},
				},
			],
			usuarios: [],
			anos: [],
			meses: MONTHS_LIST,
			model: {
				ano: '',
				user: [],
				mes_fim: '',
				mes_inicio: '',
			},
			query: '',
		}
	},
	components: {
		SelectMultiple,
		ModalResumo,
	},

	methods: {
		openModal(id, politica) {
			this.$refs.modalResumo.OpenModalResumo({
				id,
				politica,
				atendimentoID: null,
			})
		},

		async getDados(query) {
			try {
				const { data } = await axios.get(
					`${window.origin}/atendimento/reunioes_realizadas/${query}`
				)
				return data
			} catch (error) {
				console.log({ error })
			}
		},
		openFiltros() {
			modal_padrao_vue.openModal('reunioes_filtros')
		},

		formataResultsForGrid(results, resultado = []) {
			const atendimentosPorCliente = {}
			const idCliente = {}

			Object.keys(results.reunioes_realizadas_por_mes).forEach((mes) => {
				results.reunioes_realizadas_por_mes[mes].forEach((atendimento) => {
					const {
						nome_cliente,
						usuario_responsavel_atendimento,
						cliente_pf_id,
						data_atendimento,
						politica,
					} = atendimento

					if (!atendimentosPorCliente[nome_cliente]) {
						atendimentosPorCliente[nome_cliente] = []
						idCliente[nome_cliente] = { cliente_pf_id, politica }
					}
					atendimentosPorCliente[nome_cliente].push({
						usuario_responsavel_atendimento,
						mes,
						data_atendimento,
					})
				})
			})

			for (let cliente in atendimentosPorCliente) {
				resultado.push([cliente, atendimentosPorCliente[cliente]])
			}

			const formatoFinal = resultado.map(([nome_cliente, atendimentos]) => {
				const dadosCliente = idCliente[nome_cliente]
				const arrayOfficers = atendimentos.map(
					(atendimento) => atendimento.usuario_responsavel_atendimento
				)
				const arrayUnico = [...new Set(arrayOfficers.flat())]
				const user = arrayUnico

				const resultado = [
					dadosCliente.cliente_pf_id,
					dadosCliente.politica,
					nome_cliente,
					user,
				]
				results.meses_filtrados.forEach((item) => {
					const atendimentoEncontrado = atendimentos.find((a) => {
						return a.mes === item.nome
					})

					resultado.push(
						atendimentoEncontrado
							? GLOBAL.formatDateObject(
									new Date(atendimentoEncontrado.data_atendimento)
							  )
							: ''
					)
				})

				return resultado
			})
			return formatoFinal
		},

		iniciarTabelaGenerica(
			tableSelector,
			columns,
			url,
			customThenCallback,
			customTotalCallback
		) {
			const table = document.querySelector(tableSelector)

			const gridInstance = new Grid({
				search: false,
				resizable: true,
				columns,
				data: [],
				autoWidth: true,
				width: '100%',
				sort: false,
				server: {
					url: url,
					then: (data) => customThenCallback(data),
					total: (data) => customTotalCallback(data).length,
				},
				pagination: {
					enabled: false,
					limit: 10,
					summary: false,
					server: {
						url: (urlApi, page, limit) =>
							`${urlApi}&page=${++page}&paginate_by=${limit}`,
					},
				},
				style: {
					container: { border: 'none' },
					table: { border: 'none' },
					tr: { border: 'none' },
					th: { 'border-bottom': '1px solid #e9ecef' },
					td: {
						'border-bottom': '1px solid #e9ecef',
						cursor: 'pointer',
						color: '#495057',
						'font-family': 'Roboto Flex',
						'font-size': '14px',
						'font-weight': 500,
						'line-height': '150%',
						'letter-spacing': '-0.28px',
					},
					footer: { border: '' },
				},
				language: {
					search: { placeholder: 'Busque aqui...' },
					pagination: { previous: ' Anterior', next: 'Próximo ' },
				},
			})
			gridInstance.on('rowClick', (...args) => {
				const dados = args[1]

				const id = dados.cells[0].data
				const politica = dados.cells[1].data

				this.openModal(id, politica)
			})

			gridInstance?.render(table)
			return gridInstance
		},

		iniciartableReunioesRealizadas(query) {
			const resultado = []
			this.tableReunioesRealizadas = this.iniciarTabelaGenerica(
				'#table_reuniao',
				this.columns,
				`${window.origin}/atendimento/reunioes_realizadas/${query}`,
				(data) => {
					const { results } = data
					return this.formataResultsForGrid(results, resultado)
				},
				(data) => {
					const { results } = data
					return this.formataResultsForGrid(results, resultado)
				}
			)
		},
		limparFiltroReunioesRealizadas() {
			this.model = {
				ano: '',
				user: [],
				mes_fim: '',
				mes_inicio: '',
			}
			window.location.href = `${window.location.origin}/clientes/reunioes/cliente_pf/`
		},

		setQueryUrl() {
			this.query = '?'
			const idUsers = this.model?.user?.map((user) => user.value || user)
			const userJoin = idUsers.filter((idUser) => idUser)?.join(',')

			if (userJoin) this.query += `&user=${userJoin}`
			if (this.model.ano) this.query += `&ano=${this.model.ano}`
			if (this.model.mes_inicio)
				this.query += `&mes_inicio=${this.model.mes_inicio}`
			if (this.model.mes_fim) this.query += `&mes_fim=${this.model.mes_fim}`
		},

		filtrarReunioesRealizadas() {
			this.setQueryUrl()
			window.location.href = `${window.location.origin}/clientes/reunioes/cliente_pf/${this.query}`
		},
	},
	async mounted() {
		const urlParams = new URLSearchParams(window.location.search)
		const users = urlParams.get('user')?.trim().split(',')

		const responseReunioesRealizadas = await this.getDados(
			window.location.search
		)

		responseReunioesRealizadas?.results.meses_filtrados.forEach((item) => {
			this.columns.push({
				id: item.id,
				name: htmlGrid(`<div style="white-space: normal;">${item.nome}</div>`),
				width: '120px',
			})
		})
		this.usuarios = responseReunioesRealizadas.results.todos_users.map(
			(user) => ({ label: user.username, value: user.id })
		)
		this.anos = [...responseReunioesRealizadas.results.anos]

		this.model = {
			user:
				this.usuarios.filter((item) => {
					return users?.some((i) => i == item.value)
				}) || [],
			ano: Number(urlParams.get('ano')) || '',
			mes_inicio: Number(urlParams.get('mes_inicio')) || '',
			mes_fim: Number(urlParams.get('mes_fim')) || '',
		}

		this.setQueryUrl()
		setTimeout(() => {
			this.iniciartableReunioesRealizadas(this.query)
		}, 300)
	},
	delimiters: ['{*', '*}'],
}
