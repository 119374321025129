import { CARTEIRA_ALVOS_CHART_OPTIONS } from '../constants/carteira_alvos'
import { LIQUIDEZ_ALVOS_CHART_ID } from '../constants/liquidez_charts'
import { useCharts } from './useCharts'

const { createChart, setColorsInFunctionOfAHexadecimalColor } = useCharts()

export function useLiquidezAlvosChart() {
	function createLiquidezAlvosChart(limitesLiquidez) {
		const { limites, valores_liquidez } = limitesLiquidez
		
		const series = valores_liquidez.map(({ periodo, carteira, porcentagem_acumulada }) => {
			const item = limites?.find(
				(valorLiquidez) => valorLiquidez.periodo === periodo
			)

			return {
				name: periodo,
				data: [
					parseFloat(item?.objetivo) || 0,
					carteira ? parseFloat(carteira) : 0,
					
				],
			}
		})

		const seriesWithPorcentagemAcumulada = valores_liquidez.map(({ periodo, carteira, porcentagem_acumulada }) => {
			const item = limites?.find(
				(valorLiquidez) => valorLiquidez.periodo === periodo
			)

			return {
				name: periodo,
				data: [
					parseFloat(item?.objetivo) || 0,
					carteira ? parseFloat(carteira) : 0,
					porcentagem_acumulada ? parseFloat(porcentagem_acumulada) : 0
				],
			}
		})

		const options = {
			...CARTEIRA_ALVOS_CHART_OPTIONS,
			chart: {
				...CARTEIRA_ALVOS_CHART_OPTIONS.chart,
				height: 170,
			},
			series,
			title: {
				text: undefined,
			},
			tooltip:{
		y: {
			title: {
              formatter: (seriesName) => '',
          },
			formatter: (val,  {  seriesIndex, dataPointIndex, w }) => {
				const nameSerie = w.globals.seriesNames[seriesIndex]
				const itemSerie = seriesWithPorcentagemAcumulada.find(serie => serie.name === nameSerie)
				const acumulado = `${ Number(itemSerie?.data[2]).toFixed(2) }%`|| '-'
	
				return `<div class="arrow_box p-8 ">
									<p class="text-neutral-100 font-medium text-xs"> ${nameSerie}: ${parseFloat(val).toFixed(2)}%</p>			
									${w.globals.labels[dataPointIndex] === 'Carteira' ? `<p class="text-neutral-100 font-medium text-xs">Acumulado: ${acumulado} ` : ''}</p>
								</div>`
      },
		}
	},
			colors: setColorsInFunctionOfAHexadecimalColor('#7B0100', series, 20),
		}

		createChart(LIQUIDEZ_ALVOS_CHART_ID, options)
	}

	return {
		createLiquidezAlvosChart,
	}
}
