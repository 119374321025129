export const CARTEIRA_MARGINS_CHART_OPTIONS = {
	title: {
		text: 'Carteira vs Margens',
		margin: 4,
		fontWeight: 400,
		fontFamily: 'Roboto Flex, sans-serif',
		align: 'center',
		color: '#5C5C5C',
	},
	chart: {
		id: 'chartCarteiraMargens',
		fontFamily: 'Roboto Flex, sans-serif',
		type: 'bar',
		height: 600,

		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},

	dataLabels: {
		enabled: false,
	},
	plotOptions: {
		bar: {
			horizontal: true,
			barHeight: '50%',
			distributed: true,
		},
	},
	legend: {
		show: true,
		showForSingleSeries: true,
		showForNullSeries: true,
		showForZeroSeries: true,
		fontSize: '10px',
		fontFamily: 'Roboto Flex, sans-serif',
		fontWeight: 400,
	},
}

export const CARTEIRA_MARGINS_CHART_ID = '#chart-carteira-margens'
