export default {
	props: [],

	data() {},

	mounted() {},

	methods: {
		currentDate: () => {
			return new Date().toDateString()
		},
	},

	watch: {
		data: {
			handler() {
				_BIG('Nova politica')
				console.log(this.data)
			},
			deep: true,
		},
	},

	template: /* html */ `
	<div id="model-pdf" class=" w-full py-10">
			<h1 class="text-lg">Suitability UF Report</h1>
			<h2 style="color: #87849c">
				Form: Questionário de Perfil do Investidor - Suitability
			</h2>
			<table class="table-pdf-model w-full">
				<thead>
					<tr>
						<th>Status</th>
						<th>Preenchimento Forms</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Fonte</td>
						<td>Formulário de Cadastro</td>
					</tr>
					<tr>
						<td>1.1 RG / CNH (Frente e Verso)</td>
						<td>
							
								<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSqTLWA6zn1UO9UdOXNmbB_2wLJvXnrXGC_g&usqp=CAU" />
						
						</td>
					</tr>
					<tr>
						<td>1.2 Comprovante de Residência</td>
						<td>
							<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSqTLWA6zn1UO9UdOXNmbB_2wLJvXnrXGC_g&usqp=CAU" />
						</td>
					</tr>
					<tr>
						<td>2.1 Nome Completo</td>
						<td>
							Bruno dos Santos Rodrigues de Siqueira
						</td>
					</tr>
					<tr>
						<td>2.2 CPF</td>
						<td>
							033.319.897.38
						</td>
					</tr>
					<tr>
						<td>2.3 Data de Nascimento</td>
						<td>
							18-Dez-2002
						</td>
					</tr>
					<tr>
						<td>2.4 Local de Nascimento</td>
						<td>
							RJ - SG
						</td>
					</tr>
					<tr>
						<td>2.5 Profissão</td>
						<td>
							Desenvolvedor
						</td>
					</tr>

					<tr>
						<td>2.6 Telefone</td>
						<td>
							( 21 ) 98656-4067
						</td>
					</tr>

					<tr>
						<td>2.7 Celular</td>
						<td>
							( 21 ) 98656-4067
						</td>
					</tr>

					<tr>
						<td>2.8 E-mail</td>
						<td>
							contaps4762@gmail.com
						</td>
					</tr>

					<tr>
						<td>2.9 Renda Anual (R$)</td>
						<td>
							2.000,00
						</td>
					</tr>

					<tr>
						<td>2.10 Renda Familiar Anual (R$)</td>
						<td>
							30.000,00
						</td>
					</tr>

					<tr>
						<td>2.11 Qual é a sua participação na renda familiar %?</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>2.12 Número de Filhos</td>
						<td>
							2
						</td>
					</tr>

					<tr>
						<td>2.13 Idade dos Filhos</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>2.14 Nívl de Escolaridade</td>
						<td>
							Fundamental - Incompleto
						</td>
					</tr>

					<tr>
						<td>2.15 Área de Formação Acadêmica</td>
						<td>
							Astronomia
						</td>
					</tr>

					<tr>
						<td>2.16 Pessoa Politicamente Exposta?</td>
						<td>
							Sim
						</td>
					</tr>

					<tr>
						<td>3.1 Possui seguro de vida?</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>3.2 Qual o premio pago anualmente?</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td>3.3 Qual o valor da cobertura?</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>3.4 É fumante?</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>3.5 Possui seguro de saúde?</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>3.6 Qual a seguradora?</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>3.7 O plano de saúde é</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>3.8 Quanto você gasta com a mensalidade do seu
							plano?
						</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>3.9 Possui alguma doença pré existente?
						</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>4.1 Patrimônio Atual
						</td>
						<td>
							De R$ 5 milhões a R$ 10 milhões
						</td>
					</tr>

					<tr>
						<td>
							4.2 Qual é a Origem de seus Recursos?
						</td>
						<td>
							Realocação de Investimentos
						</td>
					</tr>

					<tr>
						<td>
							4.2 Qual é a Origem de seus Recursos?
						</td>
						<td>
							Realocação de Investimentos
						</td>
					</tr>

					<tr>
						<td>
							4.3 Relacione a composição atual do seu patrimônio
							(indicando os valores que possui em investimentos
							financeiros e imobilizados)
						</td>
						<td>
				
						</td>
					</tr>

					<tr>
						<td>
							4.4 Qual a parcela do seu patrimônio total deverá
							ser investida por meio da Unifinance?
						</td>
						<td>
							Menos de 10%
						</td>
					</tr>

					<tr>
						<td>
							5.1 Qual das opções a seguir melhor define seu
							objetivo de investimento?
						</td>
						<td>
							Preservação de Capital - Objetivo é obter um retorno suficiente para
							compensar a inflação, mantendo o valor real do capital constante, sem
							se expor.
						</td>
					</tr>

					<tr>
						<td>
							5.2 Qual o horizonte aproximado para seu objetivo
							de investimento citado na questão anterior?
						</td>
						<td>
							10 anos ou mais
						</td>
					</tr>

					<tr>
						<td>
							5.3 Em seus investimentos pessoais, qual parcela
							você precisará de liquidez?
						</td>
						<td>
							6 meses
						</td>
					</tr>

					<tr>
						<td>
								5.4 Que percentual de sua renda você investe
							regularmente, incluindo poupança?
						</td>
						<td>
								Entre 11% e 20%
						</td>
					</tr>

					<tr>
						<td>
							5.5 Com qual frequência você avalia o desempenho
							da sua carteira de investimentos?
						</td>
						<td>
							Poderá alterar ou resgatar investimentos em até 6 meses.
						</td>
					</tr>

					<tr>
						<td>
							6.1 Possui alguma experiência profissional no
							mercado financeiro
						</td>
						<td>
							Sim, até 5 anos
						</td>
					</tr>

					<tr>
						<td>
							6.2 Já aplicou nas seguintes opções de
							investimentos?
						</td>
						<td>
							Participações Societárias, Fundos DI
						</td>
					</tr>

					<tr>
						<td>
							6.3 Há quanto tempo investe por conta própria ou
							através de algum advisor/consultor?
						</td>
						<td>
							Menos de 3 anos
						</td>
					</tr>

					<tr>
						<td>
							7.1 Qual a variação percentual negativa máxima que
							você considera aceitável em seus investimentos em
							condições normais de mercado, no período de 30
							dias?
						</td>
						<td>
							1%
						</td>
					</tr>

					<tr>
						<td>
							7.2 Dentre os fundos de investimento abaixo, e seus
							respectivos possíveis rendimentos, qual
							representaria sua opção de investimento preferida?
						</td>
						<td>
							Fundo B - Retorno Mínimo: 2,10% ; Médio: 11,20% ; Máximo: 12,90%
						</td>
					</tr>

					<tr>
						<td>
							7.3 Em momentos de crise, o que você faria se
							houvesse redução significativa no valor do seu
							investimento?
						</td>
						<td>
							Resgataria todo o investimento e deixaria os recursos na conta corrente.
						</td>
					</tr>

					<tr>
						<td>
							8.1 Banco
						</td>
						<td>
							BB
						</td>
					</tr>

					<tr>
						<td>
							8.2 Agência 
						</td>
						<td>
						2432424
						</td>
					</tr>

					<tr>
						<td>
							8.3 Conta Corrente
						</td>
						<td>
							2344343
						</td>
					</tr>

					<tr>
						<td>
							9.1 Estado Civil
						</td>
						<td>
							Casado(a)
						</td>
					</tr>

					<tr>
						<td>
							9.2 Nome completo do cônjuge (caso aplicável)
						</td>
						<td>
				
						</td>
					</tr>

					<tr>
						<td>
							9.3 CPF do cônjuge (caso aplicável)
						</td>
						<td>
							232443322	
						</td>
					</tr>

					<tr>
						<td>
							9.4 Possui outra nacionalidade? Qual? 
						</td>
						<td>
		
						</td>
					</tr>

					<tr>
						<td>
							9.5 Ocupação Atual
						</td>
						<td>
		
						</td>
					</tr>

					<tr>
						<td>
							9.6 Empresa que Trabalha
						</td>
						<td>
							SocialBrand
						</td>
					</tr>

					<tr>
						<td>
							9.7 CNPJ da empresa 
						</td>
						<td>
							98765432345678
						</td>
					</tr>

					<tr>
						<td>
							9.8 Data de Admissão
						</td>
						<td>
							13-out-2020
						</td>
					</tr>

					<tr>
						<td>
							9.9 E-mail Profissional 
						</td>
						<td>
							test@test.com
						</td>
					</tr>

					<tr>
						<td>
							9.9 E-mail Profissional 
						</td>
						<td>
							test@test.com
						</td>
					</tr>

					<tr>
						<td>
							9.10 Endereço Comercial
						</td>
						<td>
							3444, bc, sc, 44444, Brazil
						</td>
					</tr>

					<tr>
						<td>
							9.11 Participações Societárias 
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td>
							9.12 Referências Pessoais
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td>
							9.13 Autoriza revelar, disponibilizar, compartilhar
							seus dados e/ou informações da sua conta,
							relatórios, documentos confidenciais e/ou sigilosos
							para terceiros?
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td>
							9.14 Informe Nome, CPF e e-mail da pessoa
							autorizada a receber as informações.
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td>
							9.15 Termo de Consentimento aos Serviços de
							Consultoria de Valores Mobiliários da UF Consult
						</td>
						<td>
							Agreed
						</td>
					</tr>

					<tr>
						<td>
							Terms and Conditions 
						</td>
						<td>
							Agreed
						</td>
					</tr>

					<tr>
						<td>
							9.16 Assinatura
						</td>
						<td>
							
						</td>
					</tr>

					<tr>
						<td>
							Added Time
						</td>
						<td>
							{{currentDate()}}
						</td>
					</tr>

					<tr>
						<td>
							CRM Status
						</td>
						<td>
							<div class="relative flex items-center justify-center bg-alert-green-10">
								<div class="w-1 h-full top-0 left-0 absolute bg-alert-green-100"></div>
									New Record - Record added
								</div>
						</td>
					</tr>

					<tr>
						<td>
							Referrer Name
						</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>
							SMS Status
						</td>
						<td>
						</td>
					</tr>

					<tr>
						<td>
							Task Owner
						</td>
						<td>
						</td>
					</tr>

					<tr>
						<td colspan="2">
							<h1 class="font-medium"> Comments </h1>
							<p>No Comments</p>
						</td>
					</tr>

				</tbody>
			</table>
	</div>
	`,
}
