/**
 * A composable to handle charts. It abstracts charts common features.
 * 
 * @returns chart, chartElement, createChart, setColorsInFunctionOfAHexadecimalColor
 */
export function useCharts() {
	let chart
	let chartElement

	/**
	 * This method creates a new chart using ApexCharts.
	 * 
	 * @param {*} chartElementSelector Chart element selector
	 * @param {*} options Chart options
	 */
	function createChart(chartElementSelector, options = {}) {
		chartElement = document.querySelector(chartElementSelector)
		chart = new ApexCharts(chartElement, { ...options })
		chart.render()
	}

	/**
	 * This method set a range of colors based in a unique color and a factor value that will reduce the color opacity based in this factor and the series children index.
	 * 
	 * @param {*} color 
	 * @param {*} series 
	 * @param {*} stepFactor 
	 * @returns 
	 */
	function setColorsInFunctionOfAHexadecimalColor(color, series, stepFactor = 15) {
		return Object.keys(series).map(
			(_, index) =>
				`${color}${GLOBAL.convertDecimalInHexadecimal(255 - index * stepFactor)}`
		)
	}

	return { chart, chartElement, createChart, setColorsInFunctionOfAHexadecimalColor }
}